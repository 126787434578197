import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const campaignDataAtom = atom({
    key: 'campaignDataState',
    default: null,
    effects_UNSTABLE: [persistAtom]
})


