export const privacyData = `
Last Updated: 9/2-2023

1. Introduction

This Privacy Policy outlines how Sendmea.io, owned by Code Shock, LLC, collects, uses, and protects your personal information. By using our services, you consent to the data practices described in this policy.

2. SMS and Email Marketing

We may use your contact information for SMS and email marketing purposes to provide updates and promotions related to Sendmea.io. We do not sell or share your contact information with any third parties outside of Sendmea and Code Shock, LLC. You can opt out of our marketing communications at any time through your account settings.

3. Third-Party Analytics

We utilize Facebook, Google Analytics, and AdWords to track user behavior and improve our services. While these tools collect data, we do not sell or share this information outside of our organization.

4. Data Collection Compliance

Sendmea.io adheres to all applicable rules and regulations regarding the collection and processing of personal data.

5. Personalization

We use the information collected to personalize your user experience and provide the services offered by the app.

6. Data Protection

We employ industry-standard security measures to protect your data. Regular testing and updates are conducted to ensure the security of our systems.

7. Secure Browsing

Sendmea.io enforces HTTPS/SSL protocols to ensure that your browsing experience is secure.

8. Cookies

We use cookies solely for app-related services and functionalities. We do not use cookies for user tracking or any unrelated purposes.

9. Legal Disclosures

We may disclose personal information to proper authorities if compelled by law or if it's necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.

10. Consent for Data Sharing

If there's ever a need to share your data for purposes not outlined in this policy, we will obtain your explicit consent before doing so.

11. Changes to this Policy

Sendmea.io reserves the right to update this Privacy Policy at any time. We encourage users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect.

`
