import {
  Button,
  FormControl,
  FormErrorMessage,
  Flex,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { auth } from '../../firebase/config'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useCustomToast } from '../../hooks/customToast'
import { useNavigate } from 'react-router-dom'

export default function ForgotPasswordForm() {
  const { addToast } = useCustomToast()
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  const forgetPassword = async (values) => {
    try {
      await sendPasswordResetEmail(auth, values.email)
      addToast({
        title: 'Reset Email',
        description: 'Reset link sent to email!',
        status: 'success',
        variant: 'left-accent',
      })
      navigate('/login')
    } catch ({ message }) {
      addToast({ title: 'Error', description: message, status: 'error', variant: 'left-accent' })
    }
  }

  return (
    <Flex
      minH={'70vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('white', 'gray.800')}
      spacing={8}
      w='full'
      maxW={['full', '500px']}
      py={12}
      px={[2, 6]}
      mx={'auto'}
    >
      <Stack as={'form'} onSubmit={handleSubmit(forgetPassword)} w='full'>
        <Stack
          spacing={4}
          w={'full'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          py={6}
          px={[3, 6]}
          my={12}
        >
          <Text fontWeight='bold' lineHeight={1.1} fontSize={{ base: 'lg', sm: '2xl' }}>
            Forgot your password?
          </Text>
          <Text
            fontSize={{ base: 'sm', sm: 'md' }}
            color={useColorModeValue('gray.800', 'gray.400')}
          >
            You&apos;ll get an email with a reset link
          </Text>
          <FormControl isInvalid={errors.email}>
            <Input
              placeholder='your-email@example.com'
              _placeholder={{ color: 'gray.500' }}
              {...register('email', {
                required: 'This is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
            />
            {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
          </FormControl>
          <Stack spacing={6}>
            <Button type='submit' isLoading={isSubmitting} variant={'solid'}>
              Request Reset
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  )
}
