import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Stack,
  Heading,
  Input,
  Button,
  SimpleGrid,
  FormControl,
  FormErrorMessage,
  GridItem,
  CircularProgress,
  FormLabel,
  InputLeftElement,
  InputGroup,
  Icon,
  IconButton,
  InputRightElement,
  theme,
  Box,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useCustomToast } from "../../hooks/customToast";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import { removeEmpty } from "../../utils";
import { GrFacebook } from "react-icons/gr";
import { BsInstagram } from "react-icons/bs";
import {
  AiFillTwitterCircle,
  AiFillLinkedin,
  AiOutlineLink,
  AiFillYoutube,
} from "react-icons/ai";
import { BiWorld } from "react-icons/bi";
import TickTock from "../../assets/icons/TickTock";

const SocialMedia = ({ selectedCampaign }) => {
  const { state } = useLocation();
  const { id: campaignId } = useParams();
  const id = selectedCampaign?.id || campaignId;
  const { addToast } = useCustomToast();
  const [isEditable, setIsEditAble] = useState(false);
  const campaignDetails = state || selectedCampaign;

  const socialLinks = campaignDetails?.socialLinks ? { ...campaignDetails?.socialLinks } : {};

  const initialInputs = {
    instagram: "",
    facebook: "",
    twitter: "",
    linkedIn: "",
    tickTock: "",
    youtube: "",
    other: "",
  };
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...initialInputs,
      ...socialLinks,
    },
  });

  const onSubmit = async (state) => {
    let { ...values } = state;
    try {
      const filterValues = removeEmpty({ ...values });
      if (Object.keys(filterValues).length === 0) {
        return addToast({
          id: "social-empty",
          title: "Error",
          description: "Can't save empty fields",
          status: "warning",
          variant: "left-accent",
        });
      }
      if (id) {
        const docRef = doc(db, "campaigns", id);
        await updateDoc(docRef, {
          socialLinks: filterValues,
        });

        addToast({
          id: "social-success",
          title: "Social Media !",
          description: "Updated successfully",
          status: "success",
          variant: "left-accent",
        });
        setIsEditAble(false);
      } else {
        addToast({
          id: "social-business-error",
          title: "Error",
          description: "You must add business details first",
          status: "warning",
          variant: "left-accent",
        });
      }
    } catch (error) {
      addToast({
        title: "Error",
        description: error.message,
        status: "error",
        variant: "left-accent",
      });
    }
  };

  useEffect(() => {
    if (!campaignDetails) {
      reset({ ...socialLinks });
    }
  }, []);

  return (
    <Stack
      mt={10}
      bg={"gray.50"}
      rounded={"xl"}
      py={{ base: 4, sm: 6, md: 8 }}
      px={{ base: 2, sm: 4, md: 8 }}
      width='full'
      spacing={8}>
      <VStack alignItems={"start"} justifyContent='space-between' w='full'>
        <Heading
          color={"brand.1"}
          lineHeight={1.1}
          fontSize={{ base: "xl", sm: "2xl" }}>
          {campaignDetails?.name ||
            campaignDetails?.campaignDetails?.publicCampaignName ||
            id}
        </Heading>
        <Heading
          color={"brand.1"}
          lineHeight={1.1}
          fontSize={{ base: "xl", sm: "2xl" }}>
          Social Media Links
        </Heading>
      </VStack>

      <SimpleGrid
        as={"form"}
        mt={10}
        onSubmit={handleSubmit(onSubmit)}
        columns={12}
        spacing={[2, 3, 4]}
        sx={{
          "input, select, textarea": {
            color: "gray.700",
          },
        }}>
        <FormControl
          isInvalid={!!errors?.facebook}
          as={GridItem}
          colSpan={[12, 12, 6]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            Facebook
          </FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              children={
                <Icon as={GrFacebook} color='teal.500' fontSize={"20px"} />
              }
            />
            <Input
              // size={["sm", "md", "lg"]}
              placeholder='Facebook'
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
              {...register("facebook", {
                pattern: {
                  value:
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                  message: "Invalid URL",
                },
              })}
              disabled={!isEditable}
            />
            {socialLinks?.facebook && (
              <InputRightElement>
                <IconButton
                  variant='unstyle'
                  size='sm'
                  onClick={(_) => window.open(socialLinks?.facebook)}>
                  <Icon as={BiWorld} color='teal.500' fontSize={"20px"} />
                </IconButton>
              </InputRightElement>
            )}
          </InputGroup>
          {errors.facebook && (
            <FormErrorMessage>{errors.facebook.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isInvalid={!!errors?.instagram}
          as={GridItem}
          colSpan={[12, 12, 6]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            Instagram
          </FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              children={
                <Icon as={BsInstagram} color='teal.500' fontSize={"20px"} />
              }
            />
            <Input
              // size={["sm", "md", "lg"]}
              placeholder='Instagram'
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
              {...register("instagram", {
                pattern: {
                  value:
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                  message: "Invalid URL",
                },
              })}
              disabled={!isEditable}
            />
            {socialLinks?.instagram && (
              <InputRightElement>
                <IconButton
                  variant='ghost'
                  size='sm'
                  onClick={(_) => window.open(socialLinks?.instagram)}>
                  <Icon as={BiWorld} color='teal.500' fontSize={"20px"} />
                </IconButton>
              </InputRightElement>
            )}
          </InputGroup>
          {errors.instagram && (
            <FormErrorMessage>{errors.instagram.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isInvalid={!!errors?.linkedIn}
          as={GridItem}
          colSpan={[12, 12, 6]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            LinkedIn
          </FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              children={
                <Icon as={AiFillLinkedin} color='teal.500' fontSize={"25px"} />
              }
            />
            <Input
              // size={["sm", "md", "lg"]}
              placeholder='LinkedIn'
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
              {...register("linkedIn", {
                pattern: {
                  value:
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                  message: "Invalid URL",
                },
              })}
              disabled={!isEditable}
            />
            {socialLinks?.linkedIn && (
              <InputRightElement>
                <IconButton
                  variant='ghost'
                  size='sm'
                  onClick={(_) => window.open(socialLinks?.linkedIn)}>
                  <Icon as={BiWorld} color='teal.500' fontSize={"20px"} />
                </IconButton>
              </InputRightElement>
            )}
          </InputGroup>
          {errors.linkedIn && (
            <FormErrorMessage>{errors.linkedIn.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isInvalid={!!errors?.twitter}
          as={GridItem}
          colSpan={[12, 12, 6]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            X (Twitter)
          </FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              children={
                <Icon
                  as={AiFillTwitterCircle}
                  color='teal.500'
                  fontSize={"25px"}
                />
              }
            />
            <Input
              // size={["sm", "md", "lg"]}
              placeholder='Twitter'
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
              {...register("twitter", {
                pattern: {
                  value:
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                  message: "Invalid URL",
                },
              })}
              disabled={!isEditable}
            />
            {socialLinks?.twitter && (
              <InputRightElement>
                <IconButton
                  variant='ghost'
                  size='sm'
                  onClick={(_) => window.open(socialLinks?.twitter)}>
                  <Icon as={BiWorld} color='teal.500' fontSize={"20px"} />
                </IconButton>
              </InputRightElement>
            )}
          </InputGroup>
          {errors.twitter && (
            <FormErrorMessage>{errors.twitter.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isInvalid={!!errors?.youtube}
          as={GridItem}
          colSpan={[12, 12, 6]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            YouTube
          </FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              // children={<chakra.img src={TickTock} />}
              children={
                <Icon as={AiFillYoutube} color='teal.500' fontSize={"25px"} />
              }
            />
            <Input
              // size={["sm", "md", "lg"]}
              placeholder='YouTube'
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
              {...register("youtube", {
                pattern: {
                  value:
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                  message: "Invalid URL",
                },
              })}
              disabled={!isEditable}
            />
            {socialLinks?.youtube && (
              <InputRightElement>
                <IconButton
                  variant='ghost'
                  size='sm'
                  onClick={(_) => window.open(socialLinks?.youtube)}>
                  <Icon as={BiWorld} color='teal.500' fontSize={"20px"} />
                </IconButton>
              </InputRightElement>
            )}
          </InputGroup>
          {errors.youtube && (
            <FormErrorMessage>{errors.youtube.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isInvalid={!!errors?.tickTock}
          as={GridItem}
          colSpan={[12, 12, 6]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            TikTok
          </FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              // children={<chakra.img src={TickTock} />}
              children={<TickTock fill={theme.colors.teal[500]} />}
            />
            <Input
              // size={["sm", "md", "lg"]}
              placeholder='TikTok'
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
              {...register("tickTock", {
                pattern: {
                  value:
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                  message: "Invalid URL",
                },
              })}
              disabled={!isEditable}
            />
            {socialLinks?.tickTock && (
              <InputRightElement>
                <IconButton
                  variant='ghost'
                  size='sm'
                  onClick={(_) => window.open(socialLinks?.tickTock)}>
                  <Icon as={BiWorld} color='teal.500' fontSize={"20px"} />
                </IconButton>
              </InputRightElement>
            )}
          </InputGroup>
          {errors.tickTock && (
            <FormErrorMessage>{errors.tickTock.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isInvalid={!!errors?.other}
          as={GridItem}
          colSpan={[12, 12, 12]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            Other
          </FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              children={
                <Icon as={AiOutlineLink} color='teal.500' fontSize={"25px"} />
              }
            />
            <Input
              // size={["sm", "md", "lg"]}
              placeholder='Other'
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
              {...register("other", {
                pattern: {
                  value:
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                  message: "Invalid URL",
                },
              })}
              disabled={!isEditable}
            />
            {socialLinks?.other && (
              <InputRightElement>
                <IconButton
                  variant='ghost'
                  size='sm'
                  onClick={(_) => window.open(socialLinks?.other)}>
                  <Icon as={BiWorld} color='teal.500' fontSize={"20px"} />
                </IconButton>
              </InputRightElement>
            )}
          </InputGroup>
          {errors.other && (
            <FormErrorMessage>{errors.other.message}</FormErrorMessage>
          )}
        </FormControl>

        <GridItem colSpan={[12]} order={14}>
          {!id ? (
            <Button
              type='submit'
              disabled={isSubmitting}
              mt={3}
              leftIcon={
                isSubmitting && <CircularProgress isIndeterminate size={"6"} />
              }
              variant='solid'
              ml='auto'>
              Save
            </Button>
          ) : !isEditable ? (
            <Box
              type='button'
              as='button'
              name='edit'
              mt={3}
              w={"20%"}
              borderRadius='8'
              py='2'
              bg='brand.1'
              variant='solid'
              ml='auto'
              color='white'
              fontWeight={"bold"}
              onClick={() => setIsEditAble(true)}>
              Edit
            </Box>
          ) : (
            <Button
              type='submit'
              disabled={isSubmitting}
              mt={3}
              leftIcon={
                isSubmitting && <CircularProgress isIndeterminate size={"6"} />
              }
              // w={'full'}
              variant='solid'
              ml='auto'
              // onClick={(e) => setIsEditable(e.target.checked)}
            >
              Update
            </Button>
          )}
        </GridItem>
      </SimpleGrid>
    </Stack>
  );
};

export default SocialMedia;
