import { CopyIcon } from '@chakra-ui/icons'
import {
  Heading,
  Stack,
  Text,
  VStack,
  Box,
  IconButton,
  Tooltip,
  HStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { AiOutlineGlobal } from 'react-icons/ai'
import { fireCanvaParticles } from '../../../utils'

const FinishCampaign = ({ isSubmitting, newCampaignId, user }) => {
  const [reviewLinkCopied, setReviewLinkCopied] = useState(false)
  const [wallLinkCopied, setWallLinkCopied] = useState(false)

  useEffect(() => {
    if (newCampaignId) {
      fireCanvaParticles()
    }
  }, [newCampaignId])


  return (
    <Stack width='full' spacing={4} my={[6, '8']}>
      <Stack
        width={['full', '90%', '80%']}
        mx='auto'
        my={6}
        justifyContent='center'
        alignItems={'center'}
        spacing={4}
        px={2}
      >
        <Heading
          color={'brand.1'}
          lineHeight={1.1}
          fontSize={{ base: 'lg', sm: 'xl', md: '3xl' }}
          textAlign='center'
        >
          <Text my={2}>Congratulations!</Text>
          Your video review campaign is ready!
        </Heading>
        <VStack spacing={1}>
          <Text fontSize='base' color='gray.400' textAlign='center'>
            Send this link to your customers and start getting reviews today.
          </Text>
          <Text fontSize='base' color='gray.400' textAlign='center'>
            After each new video received, you will get an email notification.
          </Text>
          <Text fontSize='base' color='gray.400' textAlign='center'>
            You can also view received reviews at any time in the received
            reviews section.
          </Text>
        </VStack>
        <VStack spacing={2} pt={4} maxW={['280px', '380px', '420px']} px={[2]}>
          <Text
            fontSize={['md', 'lg']}
            color='gray.700'
            fontWeight='600'
            fontStyle='italic'
            textAlign='center'
          >
            Reviews collection link:
          </Text>
          <HStack alignItems="center" spacing={["1", "2"]}>
            <Box
              fontSize={['sm', 'md']}
              maxH='50px'
              border='1px solid'
              borderColor='teal.100'
              rounded={'md'}
              bg='#b2f5ea61'
              color='gray.500'
              py={3}
              px={3}
              width={['280px', '370px', '430px']}
              whiteSpace='pre'
              position='relative'
              overflow='hidden'
            >
              <Text overflow='hidden' maxW='98%' fontWeight="medium">
                {window.location.origin}/review/{newCampaignId}
              </Text>
              <Tooltip
                hasArrow
                bg='teal.500'
                color='white'
                placement='top'
                label={reviewLinkCopied ? 'Copied' : 'Copy'}
              >
                <IconButton
                  size={['sm', 'md']}
                  px={"2px !important"}
                  variant='ghost'
                  aria-label='open menu'
                  color='brand.1'
                  bg={'teal.100'}
                  _hover={{
                    bg: 'teal.100',
                  }}
                  icon={<CopyIcon fontSize={23} />}
                  sx={{ position: 'absolute', right: '1%', top: '8%' }}
                  zIndex={2}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/review/${newCampaignId}`
                    )
                    setReviewLinkCopied(true)
                    setTimeout(() => {
                      setReviewLinkCopied(false)
                    }, 5000)
                  }}
                />
              </Tooltip>
            </Box>
            <IconButton
              color="teal.500"
              size="md"
              variant="ghost"
              onClick={() => window.open(`${window.location.origin}/review/${newCampaignId}`)}
            >
              <AiOutlineGlobal fontSize={25} />
            </IconButton>
          </HStack>
        </VStack>
        <VStack spacing={2} pt={4} maxW={['280px', '380px', '420px']} px={[2]}>
          <Text
            fontSize={['md', 'lg']}
            color='gray.700'
            fontWeight='600'
            fontStyle='italic'
            textAlign='center'
          >
            Reviews Wall link:
          </Text>
          <HStack alignItems="center" spacing={["1", "2"]}>
            <Box
              fontSize={['sm', 'md']}
              maxH='50px'
              border='1px solid'
              borderColor='teal.100'
              rounded={'md'}
              bg='#b2f5ea61'
              color='gray.500'
              py={3}
              px={3}
              width={['280px', '370px', '430px']}
              whiteSpace='pre'
              position='relative'
              overflow='hidden'
            >
              <Text overflow='hidden' fontWeight="medium">
                {window.location.origin}/wall/{newCampaignId}
              </Text>
              <Tooltip
                hasArrow
                bg='teal.500'
                color='white'
                placement='top'
                label={wallLinkCopied ? 'Copied' : 'Copy'}
              >
                <IconButton
                  size={['sm', 'md']}
                  px={"2px !important"}
                  variant='ghost'
                  aria-label='open menu'
                  color='brand.1'
                  bg={'teal.100'}
                  _hover={{
                    bg: 'teal.100',
                  }}
                  icon={<CopyIcon fontSize={23} />}
                  sx={{ position: 'absolute', right: '1%', top: '8%' }}
                  zIndex={2}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/wall/${newCampaignId}`
                    )
                    setWallLinkCopied(true)
                    setTimeout(() => {
                      setWallLinkCopied(false)
                    }, 5000)
                  }}
                />
              </Tooltip>
            </Box>
            <IconButton
              color="teal.500"
              size="md"
              variant="ghost"
              onClick={() => window.open(`${window.location.origin}/wall/${newCampaignId}`)}
            >
              <AiOutlineGlobal fontSize={25} />
            </IconButton>
          </HStack>

        </VStack>
      </Stack>
    </Stack >
  )
}

export default FinishCampaign
