import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Loader from '../../components/Loader'
import { auth } from '../../firebase/config'
import { userState } from '../../recoil/atoms/auth'
import { saveLogoutLogs } from '../Login/utils'
import { useCustomToast } from '../../hooks/customToast'

function Logout() {
    const [user, setUser] = useRecoilState(userState)
    const navigate = useNavigate()
    const { addToast } = useCustomToast()



    useEffect(() => {
        const logout = async () => {
            try {
                await auth.signOut()
                await saveLogoutLogs({
                    userEmail: user?.email,
                    userId: user.uid,
                    loginId: user?.loginId || ''
                })
                setUser(null)
                navigate('/login')
            } catch (error) {
                addToast({
                    title: 'Error',
                    description: error?.message || error,
                    status: 'error',
                })
            }
        }
        logout()
    }, [])

    return (
        <div>
            <Loader />
        </div>
    )

}

export default Logout