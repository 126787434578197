import React from 'react'
import { AffiliateSlider } from './Slider'
import { Box, Stack, Heading, Text, HStack, Icon, chakra } from '@chakra-ui/react'

import { FaArrowUp } from 'react-icons/fa'

const SliderBox = () => {
  return (
    <Box
      py={{
        base: '6',
        md: '10',
      }}
      px='4'
      borderRadius={12}
      w='full'
      bg='white'
      h='full'
    >
      <Stack h='100%' px='6' alignItems='center' justify={'space-between'}>
        <Heading color='brand.1'>Affiliate Commission Calculator</Heading>
        <Text fontSize='2xl' textAlign={'center'}>
          How much can you earn as a{' '}
        </Text>
        <Text fontSize={'2xl'}>
          <chakra.span color='brand.1' fontWeight={'bold'} fontSize='3xl'>
            Send
          </chakra.span>
          <chakra.span fontSize='3xl' color='brand.2' fontWeight='bold' pr='2'>
            mea
          </chakra.span>
          affiliate?{' '}
        </Text>
        <AffiliateSlider />
        {/* <Stack borderRadius='12' alignItems={'center'} justify='center' w='90%' mx='6'py='6' h='10%'  color='brand.1' > */}
        <HStack pt='4' color='brand.1'>
          <Icon
            as={FaArrowUp}
            color='brand.2'
            boxSize={{
              base: '7',
              md: '8',
            }}
          />
          <Text fontSize={'xl'} textAlign='center'>
            Slide to see the commission grow!
          </Text>
        </HStack>
        {/* </Stack> */}
      </Stack>
    </Box>
  )
}

export default SliderBox
