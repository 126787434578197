import React from "react";
import {
  Flex,
  useDisclosure,
  Button,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  FormErrorMessage,
  Text,
  Stack,
  Heading,
} from "@chakra-ui/react";
import { MdOutlineEmail } from "react-icons/md";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useCustomToast } from "../../../../hooks/customToast";
import { BsPerson } from "react-icons/bs";
// import Social from '../ProfileModal/SocialMedia';
import { useRecoilValue } from "recoil";
import { businessDataAtom } from "../../../../recoil/atoms/businessData";
import { errorToast } from "../../../../utils";

export const ContactForm = ({ closeModal, campaignEmail,campaignData }) => {
  const businessData = useRecoilValue(businessDataAtom);
  const { onClose } = useDisclosure();
  
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const { addToast } = useCustomToast();

  const onSubmit = async (data) => {
    const businessEmail = businessData ? businessData?.email : campaignEmail;
    const businessName =
      businessData?.name.charAt(0).toUpperCase() + businessData?.name.slice(1);
    try {
      const templateParams = {
        to_email: businessEmail,
        to_name: businessName,
        from_name: data.name,
        from_email: data.email,
        message: data.message,
      };
      console.log(templateParams);

      const response = await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_CONTACT_FORM_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      );
      if (response.status === 200) {
        addToast({
          title: "Email Status!",
          description: `Email Sent Successfully`,
          status: "success",
          variant: "left-accent",
        });
        onClose();
        reset();
      }
      onClose();
      closeModal();
    } catch (err) {
      errorToast(err, addToast);
      console.log(err);
    }
  };

  return (
    <>
      <Box p={"8"} mt={"4"} mb='6'>
        <Stack align={"center"} justify={"column"}>
          <Box align={"center"}>
            {" "}
            <Heading color={"#03989E"}>Contact</Heading>
          </Box>
          <Flex maxW='80%'>
            <Text textAlign={"center"} fontSize={"2xl"} color={"#03989E"}>
              {campaignData?.campaignDetails?.publicCampaignName ||
                businessData?.name}
            </Text>{" "}
          </Flex>
        </Stack>

        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack py='4' spacing={5}>
            <FormControl isInvalid={errors.name} id='name'>
              <FormLabel>Your Name</FormLabel>
              <InputGroup borderColor='#E0E1E7'>
                <InputLeftElement
                  pointerEvents='none'
                  children={<BsPerson color='gray.800' />}
                />
                <Input
                  id='name'
                  placeholder='name'
                  {...register("name", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                  type='text'
                  size='md'
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.email} id='email'>
              <FormLabel>Email</FormLabel>
              <InputGroup borderColor='#E0E1E7'>
                <InputLeftElement
                  pointerEvents='none'
                  children={<MdOutlineEmail color='gray.800' />}
                />
                <Input
                  placeholder='email'
                  id='email'
                  {...register("email", {
                    required: "This is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Not a valid email",
                    },
                    minLength: { value: 4, message: "not a valid email" },
                  })}
                  type='text'
                  size='md'
                />
                t
              </InputGroup>
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.message} id='message'>
              <FormLabel>Message</FormLabel>
              <Textarea
                borderColor='gray.300'
                _hover={{
                  borderRadius: "gray.300",
                }}
                placeholder='message'
                {...register("message", {
                  required: "This is required",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                })}
                type='text'
                size='md'
              />
              <FormErrorMessage>
                {errors.message && errors.message.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl id='send' float='right'>
              <Button isLoading={isSubmitting} type='submit' _hover={{}}>
                Send Message
              </Button>
            </FormControl>
            {/* {isDesktop ? null : <Social />} */}
          </VStack>
        </form>
      </Box>
    </>
  );
};
