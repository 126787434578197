
import React from 'react'

const LaptopIcon = ({ ...others }) => {
    return (
        <svg id="Layer_1" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" {...others}>
            <path d="m27 5h-22a1 1 0 0 0 -1 .93v12.14a1 1 0 0 0 1 .93h22a1 1 0 0 0 1-.93v-12.14a1 1 0 0 0 -1-.93z" />
            <path d="m30.92 25.92-3-5.44a1 1 0 0 0 -.92-.48h-22a1 1 0 0 0 -.92.47l-3 5.44a.63.63 0 0 0 .08.73 1.1 1.1 0 0 0 .84.36h28a1.1 1.1 0 0 0 .83-.35.63.63 0 0 0 .09-.73zm-12.92-.92h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z" />
        </svg>

    )
}

export default LaptopIcon