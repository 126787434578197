import React from 'react'
import { Box, Text, VStack, chakra } from '@chakra-ui/react'
import { motion } from 'framer-motion';
import fullLogo from '../../../assets/images/sendmea-logo.png'


const LoadingScreen = () => {
    return (
        <VStack
            bg='teal.500'
            zIndex={40}
            pos='absolute'
            w='full'
            h='full'
            justifyContent='center'
            alignItems='center'
            pl={{}}
            spacing={8}
        >
            <Box
                width={['280px', '300px', '360px']}
            >
                <motion.img
                    src={fullLogo}
                    width='100%'
                    style={{ objectFit: 'contain' }}
                    alt="my image"
                    animate={{
                        scale: [1, 1.3, 1],
                    }}
                    transition={{
                        duration: 0.8,
                        repeat: Infinity,
                        repeatType: 'reverse',
                    }}
                />
            </Box>
            <Text fontStyle='normal' fontWeight='bold' letterSpacing='0.05em' color="#7dd858" fontSize={['xl', '2xl', '3xl']} fontFamily='monospace'>
                <chakra.span color='#00c2cb'>Loa</chakra.span>ding...
            </Text>
        </VStack>
    )
}

export default LoadingScreen