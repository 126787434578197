import React from 'react'
import { Avatar, Box, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Text, VStack, theme, useColorModeValue } from '@chakra-ui/react'
import { FaMap, FaUserCircle, FaUsers } from 'react-icons/fa'
import { RiBillFill } from 'react-icons/ri'
import { GoVersions } from 'react-icons/go'
import { FiChevronDown, FiLogOut } from 'react-icons/fi'
import { teamEmailIds } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import module from '../../../../package.json'
import { HiDatabase } from 'react-icons/hi'


const ProfileMenu = ({ isLargerThan870, user, logout }) => {

    const teamMember = teamEmailIds.includes(user.email)
    const navigate = useNavigate()

    const profileLinks = [
        { label: 'Profile', href: '/dashboard/profile', icon: FaUserCircle, show: true },
        { label: 'Billing', href: '/dashboard/billing', icon: RiBillFill, show: true },
        { label: 'Affiliate Data', href: '/dashboard/affiliate-data', icon: HiDatabase, show: true },
        { label: 'Roadmap', href: '/roadmap', icon: FaMap, show: true },
        { label: 'Team', href: '/dashboard/team', icon: FaUsers, show: teamMember },
        { label: `version - ${module.version}`, disabled: true, icon: GoVersions, show: true },
        { label: `Logout`, onClick: logout, href: '/dashboard/profile', icon: FiLogOut, show: true },
    ]

    return (
        <Menu>
            <MenuButton
                py={[2]}
                pl={2}
                pr={[2, 2, 0]}
                mb={0.5}
                transition='all 0.3s'
                minW={['max-content']}
                maxW={'200px'}
                borderRadius='md'
                _hover={{
                    bg: 'gray.100',
                }}
                _focus={{ boxShadow: 'none' }}
            >
                <HStack>
                    <Avatar
                        size={'sm'}
                        src={user?.photoURL || ' '}
                        alt={user?.firstName + ' ' + user?.lastName}
                        name={user?.firstName + ' ' + user?.lastName}
                    />
                    <VStack
                        display={{ base: isLargerThan870 ? 'flex' : 'none' }}
                        alignItems='flex-start'
                        spacing='1px'
                        ml='2'
                        flex={1}
                    >
                        <Text fontSize='sm' textTransform={'capitalize'}>
                            {user?.firstName + ' ' + user?.lastName}
                        </Text>
                    </VStack>
                    <Box display={{ base: 'none', md: 'flex' }}>
                        <Icon
                            mr='4'
                            fontSize='16'
                            color='brand.1'
                            as={FiChevronDown}
                            aria-label='open menu'
                        />
                    </Box>
                </HStack>
            </MenuButton>
            <MenuList
                zIndex={10}
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                py={0} overflow='hidden'
            >
                {profileLinks.filter(link => link.show).map(link => (
                    <MenuItem
                        key={link.label}
                        sx={{
                            bg: 'white',
                            py: 3,
                            color: link.disabled && 'gray.400'
                        }}
                        _hover={{
                            bg: !link.disabled && 'teal.50',
                        }}
                        _focus={{
                            bg: 'white',
                        }}
                        cursor={link.disabled ? 'auto' : 'pointer'}
                        borderBottom={`1px solid ${theme.colors.gray[100]}`}
                        disabled={link.disabled}
                        {...(!link?.disabled && ({
                            onClick: () => { !link?.onClick ? navigate(link.href) : link.onClick() }
                        }))}
                    >
                        <HStack alignItems='center' spacing={2}>
                            <Icon as={link.icon} fontSize={'22px'} color={theme.colors.teal[500]} />
                            <Text textTransform='capitalize' fontWeight='500' fontSize={['15px']}>
                                {link.label}
                            </Text>
                        </HStack>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}

export default ProfileMenu