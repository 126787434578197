import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Box } from '@chakra-ui/react'

const AuthLayout = ({ showLoginBtn, hideNavbar, hideFooter, hideLogin, children }) => {
  return (
    <div>
      {!hideNavbar ? <Navbar /> : null}
      <Box minH="md" maxW='1500px' mx='auto'>
        {children}
      </Box>
      {!hideFooter ? <Footer showLoginBtn={showLoginBtn} hideLogin={hideLogin} /> : null}
    </div>
  )
}

export default AuthLayout
