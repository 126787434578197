import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthLayout from "../layout/AuthLayout";
import Loader from "./Loader";
import Login from '../pages/Login/Login'

export const ProtectedRoute = ({ user, children }) => {
    const { pathname } = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);

    if (user === null) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <AuthLayout children={user === null ? <Login /> : <Loader user={user} />} />
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return children
}