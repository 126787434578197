
import { Avatar, Tag, TagLabel, TagLeftIcon, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { format } from 'date-fns'
import { BiErrorAlt, BiLogInCircle, BiLogOutCircle } from 'react-icons/bi'


const LoggingCard = ({ log }) => {

    const { user, ...data } = log;

    const iconMap = {
        login: BiLogOutCircle,
        logout: BiLogInCircle,
        error: BiErrorAlt,
    };

    const colorScheme = {
        login: 'green',
        logout: 'blackAlpha',
        error: 'red',
    };

    return (
        <VStack w='full'
            key={data.id}
            p={{ base: 4, md: 4, }}
            alignItems='flex-start'
            bg="white"
            shadow="lg"
            rounded="lg"
            spacing={0}
            gap={'3'}
        >
            <VStack w='full' alignItems='center' spacing={2}>
                <VStack spacing={2} alignItems={'center'}>
                    <Avatar
                        boxSize={['50px']}
                        borderRadius={'50%'}
                        name={user?.firstName + ' ' + user?.lastName}
                        src={user?.photoURL}
                        sx={{
                            '& img': { objectFit: 'cover' }
                        }}
                        {...(data?.uid &&
                            { bg: 'transparent' }
                        )}
                    />
                    <VStack alignItems={'center'} spacing={0}>
                        <Text fontSize={['sm']} fontWeight={'500'}>{user?.firstName + " " + user?.lastName}</Text>
                        <Text fontSize={['xs']} color='gary.400' overflowWrap={'anywhere'} textAlign={'center'}>{user?.email}</Text>
                    </VStack>
                </VStack>
                <VStack spacing={0.5} alignItems={'center'}>
                    <VStack>
                        <Text fontSize={['sm']} fontWeight={'500'} textTransform={'capitalize'}>{data?.type} At:</Text>
                        <Text fontSize={['xs']} textTransform='capitalize' color='gary.400' textAlign='center'>
                            {format(new Date(data?.createdAt?.toDate()), "do MMM, yyyy 'at' h:mm a")}
                        </Text>
                    </VStack>
                    <VStack>
                        <Text fontSize={['sm']} fontWeight={'500'}>Type:</Text>
                        <Tag mt={1} size={['md']} variant='subtle' colorScheme={colorScheme[data?.type]}>
                            <TagLabel>{data?.type}</TagLabel>
                            <TagLeftIcon boxSize='12px' as={iconMap[data?.type]} ml='5px !important' mr='0 !important' />
                        </Tag>
                    </VStack>
                    <VStack>
                        <Text fontSize={['sm']} fontWeight={'500'}>Browser:</Text>
                        <Text fontSize={['sm']} textTransform='capitalize' color='gary.400'>{data?.browserName}</Text>
                    </VStack>
                </VStack>
            </VStack>
        </VStack>
    )
}

export default LoggingCard