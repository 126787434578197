import { Text } from "@chakra-ui/react"

const HowTo = () => {
  return <div>
    <Text p={16} minH={"md"}>
      HowTo
    </Text>
  </div>
}

export default HowTo
