import { Box, Link, chakra, IconButton, Stack, Text, Image } from '@chakra-ui/react'
import { AiFillInstagram, AiFillYoutube, AiOutlineTwitter } from 'react-icons/ai'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { BsPinterest, BsReddit } from 'react-icons/bs'
import { SiTiktok } from 'react-icons/si'

import Slogo from '../../../assets/images/sendmea-logo.png'

const gotoAnchor = (url) => {
    let anchor = document.createElement('a')
    anchor.target = '_blank'
    anchor.href = url
    anchor.click()
    anchor.remove()
}

export const Footer = () => (

    <Stack p={4} mt={6} bg={'rgba(180, 224, 226, 0.1)'} spacing={{ base: '4', md: '5' }}>
        <Stack justify="space-between" direction="row" >
            <Stack
                direction='row'
                pt='4'
            >
                <Image maxH='50px' width={["150px", "200px"]} src={Slogo} alt='logo' />
            </Stack>

            <chakra.div
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom: '2rem!important',
                    maxW: ['300px', '250px'],
                    gap: [3, 3],
                }}
            >
                <IconButton
                    aria-label='Visit our YouTube'
                    boxSize='45px'
                    rounded='full'
                    variant={'solid'}
                    bg='#e1f7f7'
                    color='teal.500'
                    _hover={{
                        bg: 'teal.400',
                        color: 'white',
                    }}
                    icon={<AiFillYoutube fontSize='25px' />}
                    onClick={() => gotoAnchor('https://www.youtube.com/@sendmea')}
                />
                <IconButton
                    aria-label='Visit our Instagram'
                    boxSize='45px'
                    rounded='full'
                    variant={'solid'}
                    bg='#e1f7f7'
                    color='teal.500'
                    _hover={{
                        bg: 'teal.400',
                        color: 'white',
                    }}
                    icon={<AiFillInstagram fontSize='25px' />}
                // onClick={() => gotoAnchor('https://www.youtube.com/@sendmea')}
                />
                <IconButton
                    aria-label='Visit our Facebook'
                    boxSize='45px'
                    rounded='full'
                    variant={'solid'}
                    bg='#e1f7f7'
                    color='teal.500'
                    _hover={{
                        bg: 'teal.400',
                        color: 'white',
                    }}
                    icon={<FaFacebookF fontSize='20px' />}
                    onClick={() => gotoAnchor('https://www.facebook.com/100088104594727')}
                />
                <IconButton
                    aria-label='Visit our LinkedIn'
                    boxSize='45px'
                    rounded='full'
                    variant={'solid'}
                    bg='#e1f7f7'
                    color='teal.500'
                    _hover={{
                        bg: 'teal.400',
                        color: 'white',
                    }}
                    icon={<FaLinkedinIn fontSize='20px' />}
                    onClick={() => gotoAnchor('https://www.linkedin.com/company/sendmea/')}
                />
                <IconButton
                    aria-label='Visit our Twitter'
                    boxSize='45px'
                    rounded='full'
                    variant={'solid'}
                    bg='#e1f7f7'
                    color='teal.500'
                    _hover={{
                        bg: 'teal.400',
                        color: 'white',
                    }}
                    icon={<AiOutlineTwitter fontSize='25px' />}
                    onClick={() => gotoAnchor('https://twitter.com/sendmea_review')}
                />
                <IconButton
                    aria-label='Visit our Reddit'
                    boxSize='45px'
                    rounded='full'
                    variant={'solid'}
                    bg='#e1f7f7'
                    color='teal.500'
                    _hover={{
                        bg: 'teal.400',
                        color: 'white',
                    }}
                    icon={<BsReddit fontSize='25px' />}
                    onClick={() => gotoAnchor('https://www.reddit.com/r/sendmea')}
                />
                <IconButton
                    aria-label='Visit our Pinterest'
                    boxSize='45px'
                    rounded='full'
                    variant={'solid'}
                    bg='#e1f7f7'
                    color='teal.500'
                    _hover={{
                        bg: 'teal.400',
                        color: 'white',
                    }}
                    icon={<BsPinterest fontSize='25px' />}
                    onClick={() => gotoAnchor('https://www.pinterest.com/sendmea_review/')}
                />
                <IconButton
                    aria-label='Visit our TikTok'
                    boxSize='45px'
                    rounded='full'
                    variant={'solid'}
                    bg='#e1f7f7'
                    color='teal.500'
                    _hover={{
                        bg: 'teal.400',
                        color: 'white',
                    }}
                    icon={<SiTiktok fontSize='22px' />}
                    onClick={() => gotoAnchor('https://www.tiktok.com/@teamsendmea')}
                />
                <Box>
                    <Link href='https://madepublic.io/projects/sendmea?source=embed' isExternal>
                        <Image src='https://madepublic.io/images/embed.png' alt='madepublic logo' />
                    </Link>
                </Box>
            </chakra.div>
        </Stack>

        <Text fontSize="sm" color="fg.subtle">
            &copy; {new Date().getFullYear()} Sendmea All rights reserved.
        </Text>
    </Stack>

)


