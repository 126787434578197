import React from 'react'
import  SalesNavBar  from './components/NavBar'
import {Footer} from './components/Footer'


import { Box } from '@chakra-ui/react'

const SalesPagesLayout = ({ children }) => {
  return (
    <div>

      <SalesNavBar />
      <Box minH="md" maxW='1500px' mx='auto'>
        {children}
      </Box>
      <Footer />

    </div>
  )
}

export default SalesPagesLayout
