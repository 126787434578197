import { Box, Button, Flex, HStack, useDisclosure, Stack, Image } from '@chakra-ui/react'
// import { motion } from 'framer-motion'
import { useRef } from 'react'
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom'
// import { Logo } from './Logo'
import Slogo from '../../../assets/images/sendmea-logo.png'
const HamburgerIcon = (props) => (
  <svg viewBox='0 0 80 62' width='1em' height='1em' fill='currentColor' {...props}>
    <path d='M80 0H0V6H80V0Z'></path>
    <path d='M80 28H0V34H80V28Z'></path>
    <path d='M80 56H0V62H80V56Z'></path>
  </svg>
)
const NavItem = (props) => <Box as='a' href='#' fontSize='sm' {...props} />

export const CampNav = ({ referral, wallTheme, details }) => {
  const nav = useDisclosure()
  const ref = useRef(null)
  const navigate = useNavigate()
  // console.log(referral, ' refferal')
  
  return (
    <Box as='header' bg={referral ? wallTheme['navBg'] : 'transparent'}>
      <Box px={referral ? '4' : ['0', '0', '4', '8']} bg='bg-surface'>
        <Flex align='center' justify='space-between' height='4.5rem'>
          {/* <Logo /> */}{' '}
          <Stack
            direction='row'
            // px={4}
            // py={2}

            cursor={'pointer'}
            onClick={() => navigate('/')}
          >
            {details?.wallTheme === 'medical' ? null :
              <Image maxH={referral ? '50px' : ['30px', '40px']} src={Slogo} alt='logo' />
            }
          </Stack>
          <HStack spacing='2'>
          {details?.wallTheme === 'medical' ? null :
            <Button
              display={{ base: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              variant={'outline'}
              color={'brand.1'}
              sx={{
                border: (theme) => `1px solid ${theme.colors.brand['1']}!important`,
              }}
              colorScheme='blue'
              size='sm'
              href='#'
              as={RouterLink}
              {...(referral && ({
                to: `/register${referral && `?fpr=${referral}`}`,
              }))}
              {...(!referral && ({
                _hover: {
                  background: 'transparent',
                }
              }))}
            >
              Sign Up
            </Button>
}
          </HStack>
        </Flex>
      </Box>

      {/* <Flex
        ref={ref}
        justify="center"
        align="center"
        initial={false}
        hidden={!nav.isOpen}
        aria-label="Submenu"
        bg="bg-surface"
        height="16"
        borderBottomWidth="1px"
        position="absolute"
        width="full"
        top="4.5rem"
        zIndex={-1}
        as={motion.nav}
        variants={{
          open: {
            y: '0',
            transition: {
              ease: [0.75, 0, 0.25, 1],
            },
          },
          closed: {
            y: '-100%',
          },
        }}
        animate={nav.isOpen ? 'open' : 'closed'}
        onAnimationStart={() => {
          ref.current?.removeAttribute('hidden')
        }}
        onAnimationComplete={() => {
          if (!ref.current) return
          ref.current.hidden = !nav.isOpen
        }}
      >
        <HStack spacing="6">
          <NavItem color="accent" fontWeight="semibold">
            Pricing
          </NavItem>
          <NavItem>Career</NavItem>
          <NavItem>Blog</NavItem>
          <NavItem>Careers</NavItem>
        </HStack>
      </Flex> */}
    </Box>
  )
}
