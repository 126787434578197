import React from 'react'
import {
  Box,
  chakra,
  Flex,
  Text,
  theme,
  VStack,
  FormErrorMessage,
  Fade,
  Select,
} from '@chakra-ui/react'
import { InformationModal } from '../../../components/InformationModal'
import { BsPatchQuestionFill } from 'react-icons/bs'
import { descriptionData } from './modalData'

const Circle = ({ no }) => (
  <chakra.div
    minWidth={'26px'}
    minHeight={'25px'}
    bg='brand.1'
    borderRadius='full'
    color='white'
    textAlign='center'
    display={'flex'}
    justifyContent='center'
    alignItems={'center'}
    mr='4px'
    fontSize={['sm', 'md']}
  >
    {no}
  </chakra.div>
)

export const IndustryDropDown = ({
  shouldRenders,
  register,
  errors,
  setShouldRenders,
  scrollToLastChild,
}) => {
  return (
    <div>
      <Fade in={shouldRenders.industry}>
        <VStack
          spacing={2}
          alignItems='stretch'
          maxWidth={'700px'}
          display={!shouldRenders.industry ? 'none' : 'flex'}
        >
          <Flex direction={'row'} alignItems={['center']}>
            <Circle no={4} />
            <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
              Select an Industry
            </Text>
            <InformationModal
              icon={<BsPatchQuestionFill />}
              iconColor='teal.300'
              iconSize='1.7rem'
              modalData={descriptionData}
            />
          </Flex>
          <Box
            ml={'12px !important'}
            pl='24px'
            borderLeft={`2px solid ${theme.colors.gray['200']}`}
            pb='4'
            width='full'
            pr={2}
          >
            <Select
              {...register('industry')}
              onFocus={() => {
                setShouldRenders((prev) => ({
                  ...prev,
                  campaignLogo: true,
                }))
                scrollToLastChild(shouldRenders.campaignLogo ? 3 : 4)
              }}
              placeholder='Select Industry'
            >
              <option value='b2b'>B2B Companies</option>
              <option value='catering'>Catering</option>
              <option value='cleaning_services'>Cleaning Services</option>
              <option value='coaches'>Coaches</option>
              <option value='creators'>Creators</option>
              <option value='dentists'>Dentists</option>
              <option value='event_planners'>Event Planners</option>
              <option value='freelancers'>Freelancers</option>
              <option value='home_services'>Home Services</option>
              <option value='landscaping'>Landscaping</option>
              <option value='marketers'>Marketers</option>
              <option value='real_estate'>Real Estate Agents</option>
              <option value='rei'>Real Estate Investments</option>
              <option value='travel'>Travel Agencies</option>
              <option value='web_software'>Web & Software</option>
            </Select>
          </Box>
          {errors?.publicCampaignName && (
            <FormErrorMessage>{errors?.publicCampaignName.message}</FormErrorMessage>
          )}
        </VStack>
      </Fade>
    </div>
  )
}
