import { atom } from "recoil";

export const campaignFormRenderState = atom({
    key: 'render',
    default: {
        reviewPageDetails: false,
        campaignDetails: false,
        formDetails: false,
    },
})

