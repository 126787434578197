export const terms = `

Last Updated: 9-2-2023

1. Introduction and Acceptance

By accessing and using Sendmea.io ("the Site"), a property of Code Shock, LLC, you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by these terms, please do not use this service.

2. Ownership and Location

Sendmea.io is owned and operated by Code Shock, LLC, with its principal place of business located at 2105 Vista Oeste NW, Suite E #3309, Albuquerque, NM, 87120.

3. Mandatory Arbitration Agreement

All disputes arising out of or related to these Terms of Service or use of the Site will be resolved through binding arbitration in New Mexico, in accordance with the rules of the American Arbitration Association.

4. Responsible Use

Users of Sendmea.io agree to use the product responsibly and in compliance with all applicable laws and regulations.

5. Video Reviews and Rights

Clients retain ownership of the video reviews they create. However, Sendmea.io reserves the right to use, share, market, create reactions from, advertise, and display these videos on social media and other platforms for promotional, marketing, and other related purposes.

6. Features

Sendmea.io allows users to stream, upload, share, download, delete, and archive videos.

7. Pricing

There is no free plan available on Sendmea.io. All costs related to bandwidth and storage are included in the monthly subscription price.

8. Intellectual Property

Any tools, features, or content created by Sendmea.io remain the exclusive property of Code Shock, LLC. Use of these tools or features constitutes acceptance of these terms.

9. Beta Services

Beta services provided by Sendmea.io are offered "as is" and come without warranties of any kind. Users acknowledge the potential risks associated with beta testing.

10. Age Requirements

Users must be at least 18 years old or the minimum age required to enter into a contract in their respective region.

11. Promotions

Sendmea.io may offer trials, discounts, and coupons at its discretion.

12. Refunds

No refunds will be provided upon cancellation of a subscription. The subscription will simply not renew.

13. Purchases

There are no in-app purchases available on Sendmea.io.

14. Subscription Renewal

Users can decline renewal either through the settings under 'profile' or by contacting support at least 5 days before the rebill date.

15. Acceptable Use Policy

Users must ensure their content and conduct complies with the Acceptable Use Policy as outlined in this agreement. Sendmea.io reserves the right to monitor, remove, or limit access to any content deemed in violation.

16. Copyright

Except for video reviews, all content on Sendmea.io is copyrighted and owned by Code Shock, LLC.

17. Rights Upon Termination

If a client discontinues their association with Sendmea.io, Code Shock, LLC retains the rights to use the video reviews provided indefinitely without any obligation for compensation.

18. Content Restrictions

Users are prohibited from uploading content that violates any of the restrictions outlined in this agreement, including but not limited to content that infringes third-party rights, is sexually explicit, defamatory, promotes hate or violence, or violates any applicable law.

19. Prohibited Actions

Users may not reverse engineer, scrape, redistribute, or use content from Sendmea.io without explicit written permission from Code Shock, LLC.

20. Termination

Sendmea.io reserves the right to terminate any user's access for violations of these Terms of Service, including but not limited to breaches of the Acceptable Use Policy or Content Restrictions.

`
