import { Box, chakra, Text, Tooltip, Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

function CreateWidget() {
  const { state } = useLocation()
  const { wallId } = useParams()
  const [Copied, setCopied] = useState(false)
  const [timeoutInst, setTimeoutInst] = useState(null)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutInst)
      setTimeoutInst(null)
    }
  }, [timeoutInst])

  return (
    <Box boxShadow='2xl' rounded='md' m='10' h={{ base: '100%', md: '70vh' }}>
      <Flex p='6' direction={'column'} alignItems={'center'} justify={'center'}>
        <Text fontWeight={'bold'} color={'red'} fontSize={'3xl'} my={3}>
          CURRENTLY IN DEVELOPMENT - BETA ONLY
        </Text>
        <Text fontWeight={'bold'} color={'brand.1'} fontSize={'3xl'} my={3}>
          For Embedded Review Record:
        </Text>
        <Text fontSize={['lg', 'lg']} my={3} p='4'>
          Add the following JavaScript code snippet to your website right before the closing "body"
          tag.
        </Text>

        <Box
          m='6'
          w={{ base: '90%', md: '60%' }}
          border='1px solid'
          borderColor={'gray.500'}
          p={8}
          bg={'gray.100'}
          position='relative'
          mt={1}
          pt={8}
        >
          <chakra.span
            position={'absolute'}
            top={0}
            right={0}
            left={0}
            cursor={'pointer'}
            textAlign={'right'}
            px={2}
            pb={1}
            bg={'blackAlpha.800'}
            color={'white'}
            onClick={() => {
              setCopied(true)
              setTimeoutInst(
                setTimeout(() => {
                  setCopied(false)
                }, 5000)
              )
              navigator.clipboard.writeText(`
                        <!-- Sendmea Record a Review Widget -->
                        <script>
                        (function() {
                            window.myWidgetConfig = {
                                reviewUrl: '${wallId || state?.url}',
                            };
                            var script = document.createElement('script');
                            script.src = 'https://sendmea.io/widget.js?callback=handleFileContents';
                            document.body.appendChild(script);
                        })();
                        </script>
                        <!-- End Sendmea Record a Review Widget -->
                        `)
            }}
          >
            <Tooltip
              placement='bottom'
              color='white'
              bg='teal.400'
              px={2}
              rounded='md'
              hasArrow
              label={Copied ? 'Copied' : 'Copy'}
            >
              copy code
            </Tooltip>
          </chakra.span>
          {` 
                <!-- Sendmea Record a Review Widget -->
                <script>
                (function() {
                    window.myWidgetConfig = {
                        reviewUrl: '${state?.url}',
                    };
                    var script = document.createElement('script');
                    script.src = 'https://sendmea.io/widget.js?callback=handleFileContents';
                    document.body.appendChild(script);
                })();
                </script>
                <!-- End Sendmea Record a Review Widget -->
                `}
        </Box>
      </Flex>
    </Box>
  )
}

export default CreateWidget
