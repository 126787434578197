export  const growFont = (sliderV) => {
    let f
    if (sliderV < 5) {
        f = '24px'
    }
    else if
        (sliderV < 10) {
        f = '25px'
    }
    else if
        (sliderV < 20) {
        f = '26px'
    }
    else if
        (sliderV < 30) {
        f = '27px'
    }
    else if
        (sliderV < 40) {
        f = '28px'
    }
    else if
        (sliderV < 50) {
        f = '29px'
    }
    else if
        (sliderV < 60) {
        f = '30px'
    }
    else if
        (sliderV < 70) {
        f = '31px'
    }
    else if
        (sliderV < 80) {
        f = '32px'
    }
    else if
        (sliderV < 90) {
        f = '33px'
    }
    else if
        (sliderV < 100) {
        f = '34px'
    }
    else if
        (sliderV < 110) {
        f = '35px'
    }
    else if
        (sliderV < 120) {
        f = '36px'
    }
    else if
        (sliderV < 130) {
        f = '37px'
    }
    else if
        (sliderV < 140) {
        f = '38px'
    }
    else if
        (sliderV < 150) {
        f = '39px'
    }
    else if
        (sliderV < 160) {
        f = '40px'
    }
    else if
        (sliderV < 170) {
        f = '41px'
    }
    else if
        (sliderV < 180) {
        f = '42px'
    }
    else if
        (sliderV < 190) {
        f = '43px'
    } else if
        (sliderV < 200) {
        f = '44px'
    } else if (sliderV < 210) {
        f = '45px'
    } else if (sliderV < 220) {
        f = '46px'
    } else if (sliderV < 230) {
        f = '48px'
    }
    else {
        f = '50px'
    }
    return f
}