import React, { useEffect } from "react";
import { VStack, IconButton, Tooltip } from "@chakra-ui/react";

import {
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaGlobe,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { useRecoilValue } from "recoil";
import { businessDataAtom } from "../../../../recoil/atoms/businessData";
import { useParams } from "react-router-dom";
import {
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";

const Social = () => {
  const businessData = useRecoilValue(businessDataAtom);
  const [socialLinks, setSocialLinks] = React.useState([]);
  let { campaignId } = useParams();
  const campaignSocials = {
    ...(businessData?.social && businessData?.social),
    ...(socialLinks && socialLinks),
  };

  useEffect(() => {
    const getCampagins = async () => {
      try {
        const vanityCollRefQuery = query(
          collectionGroup(db, "vanity"),
          where("vanityName", "==", String(campaignId).toLowerCase().trim())
        );
        const vanityData = await getDocs(vanityCollRefQuery);
        let getCampaignId = !vanityData.empty
          ? vanityData?.docs[0]?.ref?.parent?.parent?.id
          : campaignId;
        const campaignRef = doc(db, "campaigns", getCampaignId);
        const campaignSnap = await getDoc(campaignRef);
        const campaignData = campaignSnap.data();
        setSocialLinks(campaignData?.socialLinks || {});
      } catch (error) {
        console.log("error", error);
      }
    };
    getCampagins();
  }, []);

  return (
    <>
      <VStack
        mt={{ lg: 10, md: 10 }}
        spacing={5}
        px={5}
        mb={"6"}
        color='white'
        justifyContent='center'
        direction='column'>
        {campaignSocials?.youtube && (
          <a href={campaignSocials?.youtube}>
            <Tooltip
              bg='teal.200'
              borderColor='teal.700'
              color='teal.900'
              placement='right'
              label='YouTube'>
              <IconButton
                aria-label='youtube'
                variant='ghost'
                size='md'
                isRound={true}
                bg={"gray.500"}
                // bg={'teal.400'}
                _hover={{
                  bg: "#FF0000",
                  transform: "scale(1.05)",
                }}
                icon={<FaYoutube size='25px' />}
              />
            </Tooltip>
          </a>
        )}
        {campaignSocials?.instagram && (
          <a href={campaignSocials?.instagram}>
            <Tooltip
              bg='teal.200'
              borderColor='teal.700'
              color='teal.900'
              placement='right'
              label='instagram'>
              <IconButton
                aria-label='instagram'
                variant='ghost'
                size='md'
                isRound={true}
                bg={"gray.500"}
                // bg={'teal.400'}
                _hover={{
                  bg: "#cd486b",
                  transform: "scale(1.05)",
                }}
                icon={<FaInstagram size='25px' />}
              />
            </Tooltip>
          </a>
        )}
        {campaignSocials?.facebook && (
          <a href={campaignSocials?.facebook}>
            <Tooltip
              bg='teal.200'
              borderColor='teal.700'
              color='teal.900'
              placement='right'
              label='facebook'>
              <IconButton
                aria-label='facebook'
                variant='ghost'
                size='md'
                isRound={true}
                bg={"gray.500"}
                // bg={'teal.400'}
                _hover={{
                  bg: "#4267B2",
                  transform: "scale(1.05)",
                }}
                icon={<FaFacebookF size='25px' />}
              />
            </Tooltip>
          </a>
        )}
        {campaignSocials?.twitter && (
          <a href={campaignSocials?.twitter}>
            <Tooltip
              bg='teal.200'
              borderColor='teal.700'
              color='teal.900'
              placement='right'
              label='twitter'>
              <IconButton
                aria-label='twitter'
                variant='ghost'
                size='md'
                isRound={true}
                bg={"gray.500"}
                // bg={'teal.400'}
                _hover={{
                  transform: "scale(1.05)",
                  bg: "twitter.500",
                }}
                icon={<FaTwitter size='25px' />}
              />
            </Tooltip>
          </a>
        )}
        {campaignSocials?.tickTock && (
          <a href={campaignSocials?.tickTock}>
            <Tooltip
              bg='teal.200'
              borderColor='teal.700'
              color='teal.900'
              placement='right'
              label='tiktok'>
              <IconButton
                aria-label='ticktock'
                variant='ghost'
                size='md'
                isRound={true}
                bg={"gray.500"}
                // bg={'teal.400'}
                _hover={{
                  bg: " #00f2ea",
                  color: "#000",
                  transform: "scale(1.05)",
                }}
                icon={<FaTiktok size='25px' />}
              />
            </Tooltip>
          </a>
        )}
        {campaignSocials?.linkedIn && (
          <a href={campaignSocials?.linkedIn}>
            <Tooltip
              bg='teal.200'
              borderColor='teal.700'
              color='teal.900'
              placement='right'
              label='linkedIn'>
              <IconButton
                aria-label='linkedin'
                variant='ghost'
                size='md'
                isRound={true}
                bg={"gray.500"}
                // bg={'teal.400'}
                _hover={{
                  bg: "#0077b5",
                  transform: "scale(1.05)",
                }}
                icon={<FaLinkedinIn size='25px' />}
              />
            </Tooltip>
          </a>
        )}
        {campaignSocials?.other && (
          <a href={campaignSocials?.other}>
            <Tooltip
              bg='teal.200'
              borderColor='teal.700'
              color='teal.900'
              placement='right'
              label='other'>
              <IconButton
                aria-label='other'
                variant='ghost'
                size='md'
                isRound={true}
                // bg={'linkedin.500'}
                bg={"gray.500"}
                _hover={{
                  bg: "gray.900",
                  transform: "scale(1.05)",
                }}
                icon={<FaGlobe size='25px' />}
              />
            </Tooltip>
          </a>
        )}
      </VStack>
    </>
  );
};

export default Social;
