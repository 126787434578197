import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormErrorMessage,
  FormControl,
  Input,
  Button,
  Text,
  Box,
  Stack,
  Heading,
} from '@chakra-ui/react'
import axios from 'axios'
import { useCustomToast } from '../../hooks/customToast'
import { useIsMount } from '../../utils'

export const NewsLetter = () => {
  const [userId, setUserId] = useState(null)
  const { addToast } = useCustomToast()
  const isMount = useIsMount()
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm()

  const config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_MAILERLITE_TOKEN}`,
    },
  }
  const group_id = '79837852040431326'

  const addToGroup = (data) => {
    axios
      .post(
        `https://connect.mailerlite.com/api/subscribers/${userId}/groups/${group_id}`,
        data,
        config
      )
      .then((res) =>
        addToast({
          title: 'Email Status!',
          description: `You have been added to our newsletter!`,
          status: 'success',
          variant: 'left-accent',
        })
      )

      .catch((error) => console.log(error))
  }

  const onSubmit = async (email) => {
    await axios
      .post('https://connect.mailerlite.com/api/subscribers/', email, config)
      .then((res) => {
        setUserId(res.data.data.id)
      })

      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (!isMount) {
      addToGroup()
      reset()
    } else {
      let isMount = true
    }
  }, [userId])

  return (
    <Box mt='4rem' w='100%' bgGradient='linear(to-b, brand.1 50%, rgba(180, 224, 226, 0.1) 50%, )'>
      <Box
        bg='bg-surface'
        py={{ base: '10', md: '16' }}
        px={{ base: '6', md: '16' }}
        borderRadius='lg'
        boxShadow='sm'
      >
        <Stack
          spacing={{ base: '8', md: '8' }}
          direction={{ base: 'column', lg: 'row' }}
          justify='space-between'
        >
          <Stack spacing={{ base: '4', md: '10' }}>
            <Heading color='white' size={{ base: 'sm', md: 'md' }}>
              Sign up for our newsletter
            </Heading>
            <Text fontSize={{ base: 'lg', md: 'xl' }} color='muted'>
              Stay up to date on Sendmea!
            </Text>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing='4'
            width='full'
            justify='center'
            maxW={{ base: 'md', xl: 'lg' }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={errors.email} flex='1'>
                <Input
                  variant='filled'
                  color='gray.500'
                  type='email'
                  size='lg'
                  placeholder='Enter your email'
                  id='email'
                  {...register('email', {
                    required: 'This is required',
                    minLength: { value: 4, message: 'Minimum length should be 4' },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address',
                    },
                  })}
                />
              </FormControl>
              <Button
                bg='brand.2'
                variant='solid'
                color='white'
                size='lg'
                isLoading={isSubmitting}
                type='submit'
                mt='4'
              >
                Subscribe
              </Button>
              <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            </form>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
