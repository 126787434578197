import {
  Box,
  Button,
  Flex,
  Heading,
  Img,
  Stack,
  Text,
  useColorModeValue as mode,
  chakra
} from '@chakra-ui/react'
import * as React from 'react'
import { SingleCard } from './SingleCard'
import { LearnMore } from './LearnMore'

export const Sotellus = () => {
  return (
    <chakra.div w="full" bg={'rgba(180, 224, 226, 0.1)'} mt={['0', '4']} id="top" px={[2.5, 4]}>
      <Box as="section" py="24">
        <Box
          maxW={{
            base: 'xl',
            md: '5xl',
          }}
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
        >
          <Flex
            direction="column"
            align={{
              base: 'flex-start',
              md: 'center',
            }}
            maxW="2xl"
            mx="auto"
          >
           
            <Heading pb={'4'}><chakra.span  color={'brand.1'}>Sendmea </chakra.span>  <chakra.span color={'brand.2'} px='6'>vs </chakra.span> {"SoTellUs"}</Heading>
            <Text  fontSize={['3xl']} fontWeight={'500'} pb={'4'} textAlign={'center'}>How do we  compare? </Text>
            {/* <Text
              mt="4"
              fontSize="xl"
              textAlign={{
                base: 'left',
                md: 'center',
              }}
              color={mode('gray.600', 'gray.400')}
            >
              Est labore dolores ut consequuntur dolor vel sunt odit.
            </Text> */}
          </Flex>

          <Flex
            direction={{
              base: 'column',
              lg: 'row',
            }}
            maxW={{
              base: '560px',
              lg: 'unset',
            }}
            mx="auto"
            mt="10"
            bg={mode('white', 'gray.700')}
            rounded="xl"
          >
            <SingleCard
              flex="1"
              colorScheme="brand.2"
              name="Sendmea"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              price="$10"
              duration="general pricing here maybe"
              extras="non eligendi amet in assumenda dolorum"
              features={[
                'Lorem ipsum dolor sit amet.',
                'At atque rerum',
                'Id autem iusto ut omnis',
                'Commenting and notifications',
                'Lorem ipsum dolor sit amet.',
                'At atque rerum',
                'Id autem iusto ut omnis',
                'Commenting and notifications',
              ]}
              negatives={[]}
            />
             
            <Box
              w={{
                base: 'unset',
                lg: '1px',
              }}
              minH="0"
              h={{
                base: '1px',
                lg: 'unset',
              }}
              bg={mode('gray.100', 'gray.600')}
            />
            
            <SingleCard
              flex="1"
              colorScheme="red"
              name="Sotellus"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              price="$50"
              duration="monthly or what not"
              extras="non eligendi amet in assumenda dolorum"
              features={[
                'Lorem ipsum dolor sit amet.',
                'Quis consequuntur et soluta ',
              ]}
              negatives={['Lorem ipsum dolor sit amet.',
                'Quis consequuntur et soluta ',
                'Id autem iusto ut omnis',
                'Commenting and notifications',
                'Id autem iusto ut omnis',
                'Commenting and notifications']}
            />
          </Flex>
          <LearnMore />
          <Box mt="10" px="12" py="10" bg={mode('gray.200', 'gray.700')} rounded="xl">
            <Flex
              align="center"
              direction={{
                base: 'column',
                md: 'row',
              }}
            >
              <Stack w="full" align="center" direction="row" spacing="8">
                <Img
                  w={{
                    base: '6rem',
                    md: '8rem',
                  }}
                  h={{
                    base: '6rem',
                    md: '8rem',
                  }}
                  rounded="full"
                  objectFit="cover"
                  src="https://res.cloudinary.com/codeshock/image/upload/v1672526094/business-ge67889ce5_1280_k0leda.png"
                  alt="Customer Service"
                />
               
                <Box maxW="400px">
                  <Text fontSize="xl" fontWeight="bold">
                    Have questions?
                  </Text>
                  <Text mt="2">
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  </Text>
                </Box>
              </Stack>
              <Button
                colorScheme="blue"
                size="lg"
                mt={{
                  base: '6',
                  md: '0',
                }}
                w={{
                  base: 'full',
                  md: 'auto',
                }}
                minW="10rem"
                flexShrink={0}
                fontSize="md"
              >
                Book a demo
              </Button>
            </Flex>
          </Box>
        </Box>
      </Box>
    </chakra.div>
  )
}
