export const videoData = [
    {
      "campaignId": "AyuuwtNcvoCQN5hOggXu",
      "campaignName": "AI For Business Online Summit",
      "id": "7Y8dEq4JVGOetFvs0Mck",
      "name": "Chuck Barnett",
      "rating": 5,
      "review": "https://res.cloudinary.com/codeshock/video/upload/v1711559564/sendmea/reviews/7Y8dEq4JVGOetFvs0Mck.mp4",
      "reviewMessage": "And I thought I was pretty up to date on the AI landscape...",
      "userId": "CwBeRbgyGzUWT0gF4wXOa6c1IVU2",
      "campaignUrl": "https://sendmea.io/wall/ai4b",
      "duration": "77.3773"
    },
    {
      "campaignId": "3xDxDXNxmYDCidc3jxKE",
      "campaignName": "Arham Awan",
      "id": "Y8xHWvu1Cj8DAqcLAPWO",
      "name": "Gary", 
      "rating": 5,
      "review": "https://res.cloudinary.com/codeshock/video/upload/v1709856645/sendmea/reviews/Y8xHWvu1Cj8DAqcLAPWO.mp4",
      "reviewMessage": "Perfect gentlemen for your web applications, highly recommended, will surely work with him long term.",
      "userId": "8bwSPcBRcQaSmkr6Lc2LJqluXyC2",
      "campaignUrl": "https://sendmea.io/wall/arham-awan",
      "duration": "77.3773"
    },
    {
      "campaignId": "mQx8SLOWCygzWZB36eja",
      "campaignName": "NS Bootcamp Attendee",
      "id": "nqKv7Io4LXVwfvfdbduV",
      "name": "Sherry Goettler",
      "rating": 5,
      "review": "https://res.cloudinary.com/codeshock/video/upload/v1713043603/sendmea/reviews/nqKv7Io4LXVwfvfdbduV.mp4",
      "reviewMessage": "So much opportunity and knowledge shared!",
      "userId": "leGz4isrPdTf6YgHgfOCcxGDVIo1",
      "campaignUrl": "https://sendmea.io/wall/nsbootcamp",
      "duration": "77.3773"
    },
    {
      "campaignId": "k0nU4JM5scda8roSwJY0",
      "campaignName": "Muhammad Hussain",
      "id": "9TCZHwSkpRJopnSlbjIC",
      "name": "Chidi R Tuke",
      "rating": 5,
      "review": "https://res.cloudinary.com/codeshock/video/upload/v1705938944/sendmea/reviews/9TCZHwSkpRJopnSlbjIC.mp4",
      "reviewMessage": "Incredible Collaborator",
      "userId": "WPAhMJ0TDQQV5OqOI6ryw74Qhb12",
      "campaignUrl": "https://sendmea.io/wall/muhammad-hussain",
      "duration": "77.3773"
    },
    {
      "campaignId": "ibDlK3AwAC5mCvb1VNQw",
      "campaignName": "Review Brent",
      "id": "rUxLYq4O4HIBsxWKVHEG",
      "name": "Kaitlyn",
      "rating": 5,
      "review": "https://res.cloudinary.com/codeshock/video/upload/v1700000594/sendmea/reviews/rUxLYq4O4HIBsxWKVHEG.mp4",
      "reviewMessage": "The Land Sharks rocks!",
      "userId": "fZf7wNB1cIbxnJMdFWhLjK7MR993",
      "campaignUrl": "https://sendmea.io/wall/brent",
      "duration": "77.3773"
    },
    {
      "campaignId": "zyxuy4mJoRNK461kgQgs",
      "campaignName": "Scot Poirier",
      "id": "a3dMIjw5whdqqjEQcQnS",
      "name": "Michael Dam",
      "rating": 5,
      "review": "https://res.cloudinary.com/codeshock/video/upload/v1706299311/sendmea/reviews/a3dMIjw5whdqqjEQcQnS.mp4",
      "reviewMessage": "Real estate mentor",
      "userId": "5SYVuyf8lYaqaAsJsaJDGlogNA52",
      "campaignUrl": "https://sendmea.io/wall/scot-poirier",
      "duration": "77.3773"
    },
    {
      "campaignId": "Ik6fyMFbS157S6OkPdMM",
      "campaignName": "USLeadList",
      "id": "50w9aR6i28le3Pu5eXzL",
      "name": "Willie Argo",
      "rating": 5,
      "review": "https://res.cloudinary.com/codeshock/video/upload/v1703278780/sendmea/reviews/50w9aR6i28le3Pu5eXzL.mp4",
      "reviewMessage": "Best list we have ever used!",
      "userId": "RtpVWsSxY8YJ0UkLq6qCdIRyZF83",
      "campaignUrl": "https://sendmea.io/wall/Ik6fyMFbS157S6OkPdMM",
      "duration": "77.3773"
    },
    {
      "campaignId": "rN1WR7qd8yWBU18z19UJ",
      "campaignName": "Toni Counts",
      "id": "x0pRhV5G2hNo8joL74Sm",
      "name": "Kendra Herbe",
      "rating": 5,
      "review": "https://res.cloudinary.com/codeshock/video/upload/v1706198187/sendmea/reviews/x0pRhV5G2hNo8joL74Sm.mp4",
      "reviewMessage": "If you're ready to get empowered in the world of bookkeeping, you're in the right place!",
      "userId": "qzopDXomFZSJ7ZiadB2J8vHc9TE2",
      "campaignUrl": "https://sendmea.io/wall/toni-counts",
      "duration": "77.3773"
    },
    {
      "campaignId": "i9NwpUURJfebtwwbCLLp",
      "campaignName": "ezREIclosings",
      "id": "JHFpfKAambzlJn1c6Gww",
      "name": "Adrian Macias",
      "rating": 5,
      "review": "https://res.cloudinary.com/codeshock/video/upload/v1706573218/sendmea/reviews/JHFpfKAambzlJn1c6Gww.mp4",
      "reviewMessage": "You gained a customer for life.",
      "userId": "YEvArAb8uKU4zS1soKaC1YfKyeB3",
      "campaignUrl": "https://sendmea.io/wall/ezreiclosings",
      "duration": "77.3773"
    },
    {
      "campaignId": "uSrRACo3bqWaimAGqTms",
      "campaignName": "reviewwyattreed",
      "id": "jiK6mOWuVhZKYt8fu10q",
      "name": "Norlen B",
      "rating": 5,
      "review": "https://res.cloudinary.com/codeshock/video/upload/v1706395018/sendmea/reviews/jiK6mOWuVhZKYt8fu10q.mp4",
      "reviewMessage": "Wyatt's coaching program helped me scale my business!",
      "userId": "MYN2FYoyMOQ4bZ1GawO5j9osw7Z2",
      "campaignUrl": "https://sendmea.io/wall/reviewwyattreed",
      "duration": "77.3773"
    }
  ]