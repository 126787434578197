import { Accordion, Text, VStack, Spinner, Box } from "@chakra-ui/react";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { db } from "../../firebase/config";
import { useCollection } from "../../hooks/useCollection";
import { userState } from "../../recoil/atoms/auth";
import TestimonialAccordionItem from "./components/TestimonialAccordionItem";

const ReceivedTestimonials = () => {
  const [user] = useRecoilState(userState);
  const { documents: reviewDocs } = useCollection("reviews", [
    "userId",
    "==",
    user.uid,
  ]);
  const { documents: campaignDocs } = useCollection("campaigns", [
    "userId",
    "==",
    user.uid,
  ]);
  const { documents: campaignSpecialDocs } = useCollection("campaigns", [
    "specialAccess",
    "array-contains",
    user.email,
  ]);

  const [state, setState] = useState(null);

  useEffect(() => {
    if (campaignDocs === undefined) {
      setState(undefined);
    } else {
      if (reviewDocs && campaignDocs) {
        (async () => {
          const sorted = await Promise.all(
            campaignDocs.map(async (campaign) => {
              const subCollectionRef = collection(
                db,
                "campaigns",
                campaign.id,
                "vanity"
              );
              const subCollSnapshot = await getDocs(subCollectionRef);

              let vanity = {};
              if (!subCollSnapshot.empty) {
                let vanityName = String(
                  subCollSnapshot.docs[0]?.data()?.vanityName || ""
                )
                  .toLowerCase()
                  .trim();
                let updatedAt = subCollSnapshot.docs[0]?.data()?.updatedAt;
                let id = subCollSnapshot.docs[0]?.id;
                vanity = { vanityName, updatedAt, id };
              }

              let testimonialData = reviewDocs
                .filter((testi) => campaign.id === testi.campaignId)
                .sort((a, b) => {
                  if (a.starred && !b.starred) return -1; // a comes first
                  if (!a.starred && b.starred) return 1; // b comes first
                  return b.date?.toDate() - a.date?.toDate();
                });
              return { ...campaign, vanity, testimonialData };
            })
          );
          !campaignSpecialDocs?.length && setState(sorted);
          if (campaignSpecialDocs?.length) {
            const sendmeaCampaign = sorted?.find(
              ({ id }) => id === campaignSpecialDocs?.[0]?.id
            );
            if (!sendmeaCampaign) {
              handleSpecialAccess(setState, sorted);
            }
          }
        })();
      } else if (campaignSpecialDocs?.length) {
        handleSpecialAccess(setState, false);
      }
    }
  }, [reviewDocs, campaignDocs, campaignSpecialDocs]);

  const handleSpecialAccess = async (set, previous) => {
    const ref = collection(db, "reviews");
    const q = query(ref, where("campaignId", "==", campaignSpecialDocs[0].id));
    const sendmeaCamp = await getDocs(q);
    const result = {
      ...campaignSpecialDocs[0],
      testimonialData: sendmeaCamp.docs
        .map((v) => ({ ...v.data(), id: v.id }))
        .sort((a, b) => {
          return b.date?.toDate() - a.date?.toDate();
        }),
    };
    previous ? set([result, ...previous]) : set([result]);
  };

  return (
    <Box mt={2}>
      {state === null ? (
        <VStack
          justifyContent='center'
          alignItems='center'
          width='full'
          minH='calc(100vh - 20vh)'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='teal.500'
            size={["md", "xl"]}
          />
        </VStack>
      ) : state === undefined || !state?.length ? (
        <VStack
          justifyContent='center'
          alignItems='center'
          p={4}
          mt={10}
          minH='calc(100vh - 20vh)'>
          <Text fontSize={["xl", "3xl"]} color='brand.1' fontWeight='bold'>
            No Reviews!
          </Text>
        </VStack>
      ) : (
        <Accordion
          defaultIndex={[...Array(state?.length || 0).keys()]}
          allowMultiple>
          {state.map((campaign, idx) => (
            <TestimonialAccordionItem
              key={campaign.id}
              campaign={campaign}
              state={state}
              idx={idx}
            />
          ))}
        </Accordion>
      )}
    </Box>
  );
};

export default ReceivedTestimonials;
