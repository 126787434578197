import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const AddVerticalVideoConfirmation = ({
  isOpen,
  onClose,
  handleTriggerWebhook,
  onConfirm,
}) => {
  const size = "md";
  return (
    <Modal onClose={onClose} size={size} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Do you want to customize a vertical video as well?
        </ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <Button
            mr={2}
            onClick={() => {
              handleTriggerWebhook();
              onClose();
            }}>
            No
          </Button>
          <Button
            variant={"solid"}
            onClick={() => {
              onConfirm();
              onClose();
            }}>
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddVerticalVideoConfirmation;
