export const items1 = {

body: `Checking an option makes it visible to the viewers. `,
title: '',
tooltip: '',
tooltipPlacement: 'right',
footerButton: 'close'
}

export const items2 = {

    body: `Checking an option makes it required for submission. `,
    title: '',
    tooltip: '',
    tooltipPlacement: 'right',
    footerButton: 'close'
    }

    export const titleData = {
        body: ` `,
        title: 'Campaign Title',
        tooltip: 'example',
        tooltipPlacement: 'right',
        footerButton: 'close',
        image: '/images/campaigntitle.png'
    }

    export const descriptionData = {
        body: ` `,
        title: 'Campaign Description',
        tooltip: 'example',
        tooltipPlacement: 'right',
        footerButton: 'close',
        image: '/images/description.png'
    }

    export const logoData = {
        body: ` Provides the ratio and size your logo will be.`,
        title: 'Campaign Logo',
        tooltip: 'example',
        tooltipPlacement: 'right',
        footerButton: 'close',
        image: '/images/logoExample.png'
    }
    export const urlData = {
        body: ` `,
        title: 'Campaign Url',
        tooltip: 'example',
        tooltipPlacement: 'right',
        footerButton: 'close',
        image: '/images/urlExample.png'
    }
    export const placeHolder = {
        body: ` This is where body will be `,
        title: 'Placeholder',
        tooltip: 'placeholder',
        tooltipPlacement: 'right',
        footerButton: 'close',
        image: ''
    }