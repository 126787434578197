import React, { useEffect, useRef } from "react";
import {
  Stack,
  Heading,
  Text,
  Input,
  Button,
  SimpleGrid,
  FormControl,
  FormErrorMessage,
  GridItem,
  CircularProgress,
  Textarea,
  FormLabel,
  HStack,
  Icon,
  Flex,
  useBreakpointValue,
  FormHelperText,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { db } from "../../../firebase/config";
import {
  doc,
  updateDoc,
  collection,
  serverTimestamp,
  getDocs,
  query,
  where,
  setDoc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { BsCamera } from "react-icons/bs";
import { useCustomToast } from "../../../hooks/customToast";
import {
  blobToBase64,
  cloudfunctionsBaseURL,
  convertDashedToString,
  convertToDashed,
  convertToMb,
  errorToast,
} from "../../../utils";
import MDEditor from "@uiw/react-md-editor";
import { Select } from "chakra-react-select";

const CreateBlog = ({ fetchBlog, tabIndex, setFetchBlog, user }) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });
  const [blogDocs, setBlogDocs] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { addToast } = useCustomToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [v, setV] = useState("");
  const submitButtonRef = useRef(null);
  const [options, setOptions] = useState({});

  const initialInputs = {
    blogTitle: "",
    category: {
      label: "sendmea tips",
      value: "tips",
    },
    wordsUrl: "",
    headerImg: "",
    blogDesc: "",
    blogContent: "",
    publish: false,
    blogs: [],
  };
  const {
    handleSubmit,
    register,
    setValue,
    setError,
    clearErrors,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialInputs,
    },
  });

  const uploadToFirestore = async ({ newBlogId, ...values }, { headerImg }) => {
    const data = {
      ...values,
      headerImg,
      userId: user.uid,
    };
    // console.log({ ...data, newBlogId })
    if (!values.id) {
      const docRef = doc(db, "blogs", newBlogId);
      await setDoc(docRef, {
        ...data,
        publishedAt: serverTimestamp(),
        publishedDate: values.publish ? serverTimestamp() : "",
      });
      reset({ id: newBlogId });
      addToast({
        title: "Blog",
        description: "Added successfully",
        status: "success",
        variant: "left-accent",
      });
    } else {
      const { userId, publishedAt, ...rest } = data;
      const docRef = doc(db, "blogs", data.id);
      await updateDoc(docRef, {
        ...rest,
        headerImg,
        publishedDate: values.publish ? serverTimestamp() : "",
      });
      addToast({
        title: "Blog",
        description: "Updated successfully",
        status: "success",
        variant: "left-accent",
      });
    }
    setFetchBlog(false);
    reset({ ...initialInputs });
    setIsSubmitting(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onSubmit = async (state) => {
    setIsSubmitting(true);
    let { headerImg, ...values } = state;

    // console.log({ state })
    try {
      const blogsColl = collection(db, "blogs");
      const newBlogId = doc(blogsColl).id;
      const dashedWordsUrl = convertToDashed(state?.wordsUrl);
      const blogCollRef = query(
        blogsColl,
        where("wordsUrl", "==", dashedWordsUrl)
      );
      const checkWordURLExist = (await getDocs(blogCollRef)).docs[0]?.id;
      // console.log({ headerImg, ...values, wordsUrl: dashedWordsUrl, checkWordURLExist })
      if (
        fetchBlog &&
        dashedWordsUrl !== fetchBlog?.wordsUrl &&
        checkWordURLExist
      ) {
        setError(
          "wordsUrl",
          { message: "These words are already exists in another blog url" },
          { shouldFocus: true }
        );
        setIsSubmitting(false);
        return false;
      }
      clearErrors("wordsUrl");
      let image01 = "";
      if (typeof headerImg !== "string") {
        const base64Image = await blobToBase64(headerImg);
        let result = await fetch(
          `${cloudfunctionsBaseURL}mediaUploadToCloudinary`,
          {
            method: "POST",
            body: JSON.stringify({
              file: base64Image,
              filename: state?.id || newBlogId,
              resource_type: "image",
              folder: "sendmea/imgs",
              replace: fetchBlog?.headerImg?.includes("cloudinary"),
            }),
          }
        );
        result = await result.json();
        image01 = result.data.secure_url;
      } else {
        image01 = headerImg;
      }

      uploadToFirestore(
        {
          ...values,
          wordsUrl: dashedWordsUrl,
          newBlogId: state?.id || newBlogId,
        },
        { headerImg: image01 || headerImg }
      );
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      addToast({ title: "Error", description: error.message, status: "error" });
      setIsSubmitting(false);
    }
  };

  const handleImage = (e, name) => {
    const fileObj = e.target.files[0];
    let sizeInMB = fileObj ? convertToMb(fileObj) : 0;
    if (!fileObj) return;
    if (!fileObj?.type?.includes("image")) {
      return addToast({
        title: "Image",
        description: "Can only upload images",
        status: "error",
        variant: "left-accent",
      });
    } else if (sizeInMB > 5) {
      addToast({
        title: "Video",
        description: "Size is greater than 5mb",
        status: "error",
        variant: "left-accent",
      });
    } else {
      setValue(name, fileObj);
    }
  };

  const headerImgInputRef = useRef(null);

  useEffect(() => {
    reset({
      ...initialInputs,
      ...fetchBlog,
      wordsUrl: fetchBlog?.wordsUrl
        ? convertDashedToString(fetchBlog?.wordsUrl)
        : "",
    });
    setV(watch("blogContent"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBlog, reset]);

  const handlePublishSubmit = () => {
    setValue("publish", true);
    submitButtonRef?.current?.click();
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      [{ align: [] }],
    ],
  };

  const handleContentChange = (e) => {
    setValue("blogContent", v);
  };

  let unsubscribe;

  useEffect(() => {
    if (db && user) {
      (async () => {
        try {
          setDataLoading(true);
          const blogsRef = collection(db, "blogs");
          const blogsRefQuery = query(
            blogsRef,
            where("userId", "==", user.uid),
            orderBy("publishedAt", "desc")
          );

          // eslint-disable-next-line react-hooks/exhaustive-deps
          unsubscribe = onSnapshot(blogsRefQuery, (blogsSnap) => {
            // const blogsSnap = await getDocs(blogsRefQuery)
            let results = [];
            blogsSnap.docs.forEach((doc) => {
              results.push({ ...doc.data() });
            });
            // console.log(results)
            setBlogDocs(results);
            // console.log(blogDocs)
            setDataLoading(false);
            let result = results?.map((person) => ({
              label: person.blogTitle,
              value: person.id,
            }));
            setOptions(result);
            console.log(result);
          });
        } catch (error) {
          errorToast(error, addToast);
          // setDataLoading(false)
        }
      })();
    }
    return () => unsubscribe();
  }, [user]);

  const blogOptions = [
    {
      label: "news",
      value: "news",
    },
    {
      label: "sendmea tips",
      value: "tips",
    },
  ];

  return (
    <Stack
      // mt={10}
      bg={"gray.50"}
      rounded={"xl"}
      py={{ base: 4, sm: 6, md: 8 }}
      px={{ base: 2, sm: 4, md: 8 }}
      width='full'
      spacing={8}
      // maxW={{ lg: 'lg' }}
    >
      <Heading
        color={"brand.1"}
        lineHeight={1.1}
        fontSize={{ base: "xl", sm: "2xl" }}>
        {watch("id") ? "Edit" : "Add"}
      </Heading>
      <SimpleGrid
        as={"form"}
        mt={10}
        onSubmit={handleSubmit(onSubmit)}
        columns={12}
        spacing={[2, 4, 6]}
        sx={{
          "input, select, textarea": {
            color: "gray.700",
          },
        }}>
        <FormControl
          isInvalid={!!errors?.blogTitle}
          as={GridItem}
          colSpan={[12]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            Title
          </FormLabel>
          <Input
            size={["sm", "md", "lg"]}
            placeholder='Title'
            bg={"gray.100"}
            border={0}
            color={"gray.500"}
            _placeholder={{
              color: "gray.500",
            }}
            {...register("blogTitle", {
              required: "This is required",
            })}
          />

          {errors.blogTitle && (
            <FormErrorMessage>{errors.blogTitle.message}</FormErrorMessage>
          )}
        </FormControl>
        {/* <Controller
          control={control}
          name='category'
          rules={{ required: "Please enter at least one food group." }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => (
            <FormControl colSpan={[12]} as={GridItem}>
              <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
                Category
              </FormLabel>
              <Select
                name={name}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                options={blogOptions}
                placeholder='Select category...'
                closeMenuOnSelect={false}
              />
            </FormControl>
          )}
        /> */}

        <FormControl
          isInvalid={!!errors?.wordsUrl}
          as={GridItem}
          colSpan={[12]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            Word For URL
          </FormLabel>
          <Input
            size={["sm", "md", "lg"]}
            placeholder='e.g how video reviews help business'
            bg={"gray.100"}
            border={0}
            color={"gray.500"}
            _placeholder={{
              color: "gray.500",
            }}
            {...register("wordsUrl", {
              required: "This is required",
              minLength: {
                value: 6,
                message: "Minimum length should be 6",
              },
              validate: (value) =>
                /^[a-zA-Z0-9\s]+$/.test(value) ||
                "No special characters allowed",
            })}
          />
          {errors.wordsUrl ? (
            <FormErrorMessage>{errors.wordsUrl.message}</FormErrorMessage>
          ) : (
            <FormHelperText>
              URL: https://sendmea.io/blog/{convertToDashed(watch("wordsUrl"))}
            </FormHelperText>
          )}
        </FormControl>
        <Controller
          control={control}
          name='blogs'
          rules={{ required: "Please enter at least one food group." }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => (
            <FormControl colSpan={[12]} as={GridItem}>
              <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
                Select Training
              </FormLabel>
              <Select
                isMulti
                name={name}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                options={options}
                placeholder='Select some colors...'
                closeMenuOnSelect={false}
              />
            </FormControl>
          )}
        />
        <Flex
          as={GridItem}
          colSpan={[12]}
          flexWrap='wrap'
          gap='10px'
          alignItems='center'
          minH={["max-content", "max-content", "75px"]}>
          <FormControl
            isInvalid={!!errors?.headerImg}
            width='full'
            pos='relative'>
            <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
              Header Image
            </FormLabel>
            <HStack
              alignItems='center'
              px={["2"]}
              py={["2", "3"]}
              bg={"gray.200"}
              borderRadius='4px'
              maxH='45px'
              whiteSpace='pre'
              overflow='hidden'
              // position={["absolute", "absolute", "relative"]}
              width='full'>
              <Button
                leftIcon={
                  <Icon
                    as={BsCamera}
                    fontSize={["18px"]}
                    mr={["-2", "-2", "0"]}
                  />
                }
                size={["sm"]}
                variant='solid'
                py={4}
                minW={["32px", "32px", "90px"]}
                rounded={["full", "full", "md"]}
                onClick={() =>
                  headerImgInputRef.current && headerImgInputRef.current.click()
                }>
                {isMobile ? "" : "Upload"}
              </Button>
              <Text
                fontSize={["sm", "md"]}
                pr={20}
                overflow='hidden'
                whiteSpace='pre'
                userSelect='none'
                color={"gray.700"}
                fontStyle='italic'>
                {watch("headerImg")
                  ? typeof watch("headerImg") === "object"
                    ? watch("headerImg")?.name
                    : watch("headerImg")
                  : "Click to upload header image"}
              </Text>
            </HStack>
            <Input
              type='file'
              accept='image/*'
              style={{ display: "none" }}
              {...register("headerImg", {
                required: "This is required",
              })}
              ref={headerImgInputRef}
              onChange={(e) => handleImage(e, "headerImg")}
            />
            {errors.headerImg && (
              <FormErrorMessage>{errors.headerImg.message}</FormErrorMessage>
            )}
          </FormControl>

          {/* {watch("headerImg") &&
                        <Image
                            mt={["50px", "50px", "0px"]}
                            objectFit='contain'
                            src={typeof watch("headerImg") === "object" ? URL.createObjectURL(watch("headerImg")) : watch("headerImg")}
                            alt='Dan Abrams'
                            width={["80px", "100px"]}
                            height={["80px", "80px"]}
                        />
                    } */}
        </Flex>

        <FormControl
          isInvalid={!!errors?.blogDesc}
          as={GridItem}
          colSpan={[12]}
          size={["sm", "md", "lg"]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            Description
          </FormLabel>
          <Textarea
            minH='50px'
            placeholder='Description'
            bg={"gray.100"}
            border={0}
            rows={3}
            resize='none'
            color={"gray.500"}
            _placeholder={{
              color: "gray.500",
            }}
            {...register("blogDesc", {
              required: "This is required",
              maxLength: { value: 120, message: "max length is 120" },
            })}
          />
          {errors?.blogDesc ? (
            <FormErrorMessage>{errors.blogDesc.message}</FormErrorMessage>
          ) : (
            <FormHelperText
              color={watch("blogDesc")?.length > 120 ? "red.500" : "gray.600"}>
              {watch("blogDesc")
                ? `${120 - watch("blogDesc")?.length} remaining`
                : "Max Length 120"}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          isInvalid={!!errors?.blogContent}
          as={GridItem}
          colSpan={[12]}
          size={["sm", "md", "lg"]}>
          <FormLabel fontSize={["sm", "md"]} color='gray.500' ml={0.5}>
            Content
          </FormLabel>
          {/* <chakra.div bg={'gray.100'} border={0} minH='400px'>
            <ReactQuill
              placeholder='Content'
              modules={modules}
              onChange={handleContentChange}
              value={watch('blogContent')}
              style={{ height: '355px', marginBottom: '1rem' }}
            />
          </chakra.div> */}
          {/* {errors?.blogContent && (
            <FormErrorMessage>{errors?.blogContent.message}</FormErrorMessage>
          )} */}
          <div data-color-mode='light'>
            <MDEditor height={600} value={v} onChange={setV} />
          </div>
        </FormControl>

        <GridItem colSpan={[12]}>
          <HStack
            justifyContent='flex-start'
            alignItems='center'
            spacing={3}
            mt={3}>
            <Button
              type='submit'
              disabled={isSubmitting}
              leftIcon={
                isSubmitting && <CircularProgress isIndeterminate size={"6"} />
              }
              variant='solid'
              ml='auto'
              ref={submitButtonRef}
              onClick={() => {
                handleContentChange();
              }}>
              {watch("id") ? "Update" : "Save"}
            </Button>
            {!watch("id") && (
              <Button
                type={"button"}
                variant='solid'
                ml='auto'
                isLoading={isSubmitting}
                onClick={handlePublishSubmit}>
                Publish
              </Button>
            )}
          </HStack>
        </GridItem>
      </SimpleGrid>
    </Stack>
  );
};

export default CreateBlog;
