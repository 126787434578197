// import { Box, useColorModeValue } from '@chakra-ui/react'
import React from "react";
import {
  Text,
  HStack,
  Icon,
  Stack,
  Box,
  Wrap,
  Heading,
  Badge,
} from "@chakra-ui/react";
import {
  HiCash,
  HiLocationMarker,
  HiPhone,
  HiOutlineMail,
} from "react-icons/hi";
// import { CustomerReviews } from './CustomerReviews'
import { useRecoilValue } from "recoil";
import { businessDataAtom } from "../../../../recoil/atoms/businessData";
import Rating from "../../../../components/Rating";

export const ProfileBody = ({ campaignData }) => {
  const businessData = useRecoilValue(businessDataAtom);
 
  return (
    <Box p={"10"} mt={"6"}>
      <Stack
        spacing={{
          base: "1",
          md: "2",
        }}
        direction={{
          base: "column",
          md: "row",
        }}>
        <Heading py={"6"} fontWeight='bold' fontSize='2xl'>
          {campaignData?.campaignDetails?.publicCampaignName ||
            businessData?.name}
        </Heading>
        <HStack
          fontSize={{
            base: "md",
            md: "lg",
          }}></HStack>
      </Stack>
      <HStack py='3'>
        <Icon fontSize='xl' as={HiPhone} color='gray.400' />
        <Text mt='2' fontSize='sm' fontWeight='medium' color={"gray.700"}>
          {businessData?.phone}
        </Text>
        <Icon fontSize='xl' as={HiOutlineMail} color='gray.400' />
        <Text mt='2' fontSize='sm' fontWeight='medium' color={"gray.700"}>
          {businessData?.email}
        </Text>
      </HStack>
      <HStack spacing='1'>
        {businessData?.country ? (
          <>
            <Icon as={HiLocationMarker} color='gray.400' />
            <Text fontSize='sm' fontWeight='medium' color={"gray.700"}>
              {" "}
              {businessData?.country}
            </Text>
          </>
        ) : null}
      </HStack>
      <Wrap pt='4' shouldWrapChildren my='2' spacing='4'>
        <HStack direction='row' alignItems='center' spacing={"10px"}>
          <Badge
            colorScheme='orange'
            variant='solid'
            px='6px'
            py={"1px"}
            rounded='full'
            mb={1}>
            {Math.round(campaignData?.avgRating || 0)}
          </Badge>
          <Rating
            total={5}
            value={Math.round(campaignData?.avgRating || 0)}
            size={["16px"]}
            activeStarColor='orange.400'
            isStatic={true}
            showNumber={false}
          />
          <Text fontSize='15px' color='gray.800' pb={1.5}>
            ({campaignData?.totalTestimonials || 0})
          </Text>
        </HStack>
        {/* <CustomerReviews
					reviewCount={campaignData?.totalTestimonials}
					rating={Math.round(campaignData?.avgRating) || 0}
				/> */}
        <HStack>
          <Icon as={HiCash} fontSize='xl' color='gray.500' />
        </HStack>
      </Wrap>
      <Box pt={"6"} fontSize='sm'>
        {businessData?.description}
      </Box>
    </Box>
  );
};
