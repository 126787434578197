import {
    Text,
    Container,
    Flex,
    IconButton,
    useBreakpointValue,
    useDisclosure, Modal, ModalOverlay, Tooltip, Avatar, ModalContent, ModalCloseButton, chakra
} from '@chakra-ui/react'
import * as React from 'react'
import { ContactForm } from './ContactForm'
import { Sidebar } from './Sidebar'
import { MobileBar } from './MobileBar'
import {
    MdOutlineEmail,
} from 'react-icons/md';



export const ContactModal = ({ disableIcon,campaignData }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    })
    return (

        <>
            <Tooltip
                label="Contact"
                fontSize='md'
                placement='bottom'
                bg='teal.400'
                rounded='md'
                hasArrow
                closeOnPointerDown>
                <IconButton
                    variant="solid"
                    icon={<MdOutlineEmail fontSize="2rem" />}
                    aria-label="Open Menu"
                    color={'gray.50'}
                    bg={'#78bdc0'}
                    borderRadius="full"
                    onClick={() => !disableIcon && onOpen()}
                    sx={{ width: '55px', height: '55px' }}
                />
            </Tooltip>
            <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent maxW="50rem" >
                    <Flex
                        as="section"
                        direction={{
                            base: 'column',
                            lg: 'row',
                        }}
                        // minHeight="60vh"
                        bg="bg-canvas"
                        overflowY="auto"
                    >

                        {isDesktop ? <Sidebar /> : null}

                        <Container py="2" flex="1">


                            <ContactForm closeModal={onClose} campaignData={campaignData}/>
                            {isDesktop ? null : <MobileBar />
                            }

                        </Container>

                    </Flex>
                    <ModalCloseButton />
                </ModalContent>
            </Modal>
        </>

    )
}
