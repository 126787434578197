import { BsStars } from 'react-icons/bs'
import { IoRocketSharp } from 'react-icons/io5'

export const features = [
  {
    name: 'Are you ready to take your business to the next level?',
    description: `Look no further than Sendmea's
    Video Review referral program! Not only will you earn a generous $30 monthly commission
    for the life of the customer you refer, but by utilizing video reviews, you'll be giving
    your small business or solo venture the boost it needs to stand out in the industry and
    attract even more customers!`,
    icon: BsStars,
  },
  {
    name: `Don't miss out on this opportunity to grow your business and
    earn some extra income!`,
    description: `Sign up for Sendmea's referral program today and make over $1,000 per month with only 31
      referrals!.`,
    icon: IoRocketSharp,
  },
]
