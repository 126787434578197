import { useToast } from '@chakra-ui/react'

export const useCustomToast = () => {
  const toast = useToast()

  const addToast = ({ id, title, description, status, ...options }) => {
    if (id && toast.isActive(id)) return false
    toast({
      ...(id && { id }),
      title,
      description,
      status,
      position: 'top-right',
      isClosable: true,
      duration: 3000,
      variant: 'left-accent',
      ...options,
    })
  }

  return { addToast, toast }
}
