import { Box, Button, chakra, Text } from '@chakra-ui/react'
import React from 'react'

function Completed({ campaign }) {

    const { reviewPageDetails } = campaign;

    return (
        <chakra.div minH={['48vh', '64vh']} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={"center"}
            p={{ base: 4, sm: 6, md: 8 }}
        >
            <Box
                bg={'teal.50'}
                rounded={'xl'}
                p={{ base: 4, sm: 6, md: 8 }}
                py={[8, 10, 12]}
                maxWidth="700px"
            >
                <Text fontWeight={'bold'} color={'brand.3'}>
                    Your review has been sent successfully
                </Text>
                <Text fontWeight={'bold'} fontSize={['1.4rem', '2rem']} color={'brand.1'}>
                    {reviewPageDetails?.thanksForLeavingText || 'Thank you for your time! We appreciate your testimony and hope to see you again soon.'}
                </Text>
                <Button mt={'3'} variant={'solid'} as={'a'}
                    href={reviewPageDetails?.redirectLinkForButton || '#'}
                    target={reviewPageDetails?.redirectLinkForButton && '_blank'}
                >
                    {reviewPageDetails.buttonText || 'Go'}
                </Button>
            </Box>
        </chakra.div>
    )
}

export default Completed