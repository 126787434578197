import { addDoc, collection, doc, serverTimestamp, setDoc } from "firebase/firestore"
import { db } from "../../firebase/config"
import { fnBrowserDetect } from "../../utils"

export const saveLoginLogs = (data) => new Promise(async (resolve, reject) => {
    try {
        const logCollRef = collection(db, 'logs')
        const loginId = doc(logCollRef).id
        await setDoc(doc(db, 'logs', loginId), {
            createdAt: serverTimestamp(),
            browserName: fnBrowserDetect(),
            type: 'login',
            ...data
        })
        resolve(loginId)
    } catch (error) {
        reject(error)
    }
})


export const saveLogoutLogs = (data) => new Promise(async (resolve, reject) => {
    try {
        const logCollRef = collection(db, 'logs')
        await addDoc(logCollRef, {
            createdAt: serverTimestamp(),
            browserName: fnBrowserDetect(),
            type: 'logout',
            ...data
        })
        resolve(true)
    } catch (error) {
        reject(error)
    }
})