import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const initialValues = {
    name: "",
    companyName: "",
    email: "",
    position: "",
    profileURL: "",
    testimonial: "",
    duration: '',
    date: '',
    id: "",
    userId: '',
    campaignId: '',
    like: false
}

export const reviewState = atom({
    key: 'review',
    default: initialValues,
    effects_UNSTABLE: [persistAtom]
})