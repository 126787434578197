import { Box, chakra, Text, Tooltip, Flex, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { db } from "../../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import { Input, FormControl, FormLabel } from "@chakra-ui/react";

function AllReviewsEmbed() {
  const { state } = useLocation();
  const { wallId } = useParams();
  const [Copied, setCopied] = useState(false);
  const [timeoutInst, setTimeoutInst] = useState(null);
  const [vanityName, setVanityName] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  // console.log({ state })
  useEffect(() => {
    return () => {
      clearTimeout(timeoutInst);
      setTimeoutInst(null);
    };
  }, [timeoutInst]);

  useEffect(() => {
    const getDocumentWithSubCollection = async () => {
      try {
        const subCollectionRef = collection(db, "campaigns", wallId, "vanity");
        const subCollectionSnapshot = await getDocs(subCollectionRef);
        subCollectionSnapshot.forEach((doc) => {
          console.log(doc.id, "=>", doc.data());
          setVanityName(doc.data()?.vanityName || "");
        });
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };
    if (wallId) {
      getDocumentWithSubCollection();
    }
  }, [wallId]);

  const handleColorChange = (event) => {
    setBackgroundColor(event.target.value);
  };

  return (
    <Box
      boxShadow='2xl'
      rounded='md'
      m='10'
      minH={{ base: "100%", md: "70vh" }}>
      <Flex p='6' direction={"column"} alignItems={"center"} justify={"center"}>
        <Text fontWeight={"bold"} color={"brand.1"} fontSize={"3xl"} my={3}>
          Coming soon All Reviews
        </Text>
        <Text fontSize={["lg", "lg"]} my={3} p='4'>
          Add the following JavaScript code snippet to your website where you
          want the Video Reviews to appear.
        </Text>

        <VStack align='start' spacing={2} mb={3}>
          <FormControl>
            <FormLabel htmlFor='backgroundColor'>
              Embed Wall Background Color
            </FormLabel>
            <Input
              type='color'
              id='backgroundColor'
              onChange={handleColorChange}
              value={backgroundColor}
              size='md'
            />
          </FormControl>
          <Text fontSize='sm' color='gray.600'>
            Note: This sets the background color for the embed wall. Default is
            white (#FFFFFF).
          </Text>
        </VStack>

        <Box
          m='6'
          w={{ base: "90%", md: "60%" }}
          border='1px solid'
          borderColor={"gray.500"}
          p={8}
          bg={"gray.100"}
          position='relative'
          mt={1}
          pt={8}>
          <chakra.span
            position={"absolute"}
            top={0}
            right={0}
            left={0}
            cursor={"pointer"}
            textAlign={"right"}
            px={2}
            pb={1}
            bg={"blackAlpha.800"}
            color={"white"}
            onClick={() => {
              setCopied(true);
              setTimeoutInst(
                setTimeout(() => {
                  setCopied(false);
                }, 5000)
              );
              navigator.clipboard.writeText(`
                        <!-- Sendmea Video Reviews Widget -->
                        <iframe
                        src="${window.location.origin}/wall/${
                vanityName || wallId
              }?embeded=true${
                backgroundColor &&
                `&background=${backgroundColor.replace("#", "")}`
              }"
                        width="100%"
                        height="750"
                        style="border: 0"
                    />
                        <!-- End Sendmea Video Reviews Widget -->
                        `);
            }}>
            <Tooltip
              placement='bottom'
              color='white'
              bg='teal.400'
              px={2}
              rounded='md'
              hasArrow
              label={Copied ? "Copied" : "Copy"}>
              copy code
            </Tooltip>
          </chakra.span>
          <div id='sendmea_container'></div>
          {` 
                <!-- Sendmea Video Reviews Widget -->
                <iframe
                src="${window.location.origin}/wall/${
            vanityName || wallId
          }?embeded=true${backgroundColor && `&background=${backgroundColor}`}"
                width="100%"
                height="750"
                style="border: 0"
            />
                <!-- End Sendmea Video Reviews Widget -->
                `}
        </Box>
      </Flex>

      {/* <iframe
        src={`http://localhost:3001/wall/muhammad-hussain?embeded=true&`}
        width='100%'
        height='680'
        // style='border: 0'
      /> */}
    </Box>
  );
}

export default AllReviewsEmbed;
