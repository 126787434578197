


export const modalData = {

body: `A campaign is a unique business or product that would get its own reviews. For instance,
if you own two business, each business could be a campaign. `,
title: 'What is a campaign?',
tooltip: 'What is a campaign?',
tooltipPlacement: 'right',
footerButton: 'close',
video: 'https://res.cloudinary.com/codeshock/video/upload/v1676952366/What_is_a_Sendmea_campaign__1_zjk7yh.mp4'

}