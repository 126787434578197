export const faqs = [
  {
    id: '1',
    question: 'How does Sendmea work?',
    answers: [
      "Sendmea is an online video review platform that allows businesses to collect and showcase customer reviews through custom campaigns. Businesses create a campaign and share a link with customers, who can then easily record and submit video reviews using their desktop or mobile device. Once approved, the videos can be displayed on the business's custom review page and shared on social media to increase online awareness and credibility. With Sendmea, businesses can easily gather social proof and increase their online presence.",
    ],
  },
  {
    id: '2',
    question: 'What are the benefits of using Sendmea for collecting video reviews?',
    answers: [
      'Sendmea provides various tools and features to help businesses collect high-quality video reviews. We offer an easy and streamlined process for customers to record and submit their reviews, and we also provide customizable forms to gather additional information such as social profiles and job titles. In addition, our platform allows businesses to review and approve each video before it is published, and we offer tools for downloading and sharing approved reviews on social media or other marketing channels.',
    ],
  },
  {
    id: '3',
    question: 'Which industries or types of businesses can benefit from Sendmea?',
    answers: [
      'Yes! With Sendmea, you can customize the review form to ask specific questions that are relevant to your business. This helps you gather valuable feedback from your customers and also allows you to highlight specific aspects of your business that you want to showcase. You can also add incentives such as discounts and promotional offers to encourage more customers to participate in your video review campaigns.',
    ],
  },
  {
    id: '4',
    question: 'What is the pricing for Sendmea?',
    answers: [
      'Sure! Sendmea pricing starts at $169 per month, but you can get a $50 off discount for life. This brings the cost down to $119 per month.',
    ],
  },
  {
    id: '5',
    question:
      'How can I share the video reviews collected using Sendmea on my website and social media?',
    answers: [
      "Getting started with Sendmea is quick and easy. Simply sign up for an account on our website and complete the payment process. Once you're signed up, you can start creating campaigns, sending review requests, and collecting video reviews. If you have any questions or need assistance, our customer support team is always available to help.",
    ],
  },
]
export const faqsAffiliate = [
  {
    id: '1',
    question: 'What is the Sendmea Affiliate program and how does it work?',
    answers: [
      'The Sendmea Affiliate program allows individuals and businesses to earn a commission by referring new customers to Sendmea. Affiliates receive a unique referral link and earn a $30 commission on each monthly subscription payment made by their referred customers. The commission is paid out on a net 30 basis and affiliates can access marketing materials and track their earnings through a dedicated affiliate dashboard. To participate in the affiliate program, individuals must first become a Sendmea product user and enroll in the program through the affiliate data section in their account.',
    ],
    links: '',
  },
]
