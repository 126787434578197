import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Heading,
  HStack,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { InformationModal } from '../../../components/InformationModal'
import { BsPatchQuestionFill } from 'react-icons/bs'
import { items1, items2 } from './modalData'

const FormDetails = ({ stepMethods, getData, state, setIsDone, isSubmitting }) => {
  let { nextStep, prevStep, activeStep } = stepMethods

  const initialAskUsersArray = [
    { label: 'Name', name: 'name', checked: true, required: true },
    { label: 'Email', name: 'email', checked: true, required: true },
    {
      label: 'Address',
      name: 'address',
      checked: false,
      required: false,
    },
    { label: 'Rating', name: 'rating', checked: true, required: true },
    { label: 'Review Title', name: 'reviewMessage', checked: true, required: true },
    {
      label: 'Company Name',
      name: 'companyName',
      checked: false,
      required: false,
    },
    { label: 'Job Title', name: 'position', checked: false, required: false },
    {
      label: 'Add a url input (social media)',
      name: 'profileURL',
      checked: false,
      required: false,
    },
    {
      label: 'Web Page',
      name: 'webPage',
      checked: false,
      required: false,
    },
    ...(state?.formDetails?.askUsers || []),
  ]
  const {
    handleSubmit,
    register,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      askUsers: [
        ...new Map(initialAskUsersArray.map((question) => [question.name, question])).values(),
      ],
    },
  })

  const modalData = {
    body: `A campaign is a unique business or product that would get its own reviews. For instance,
  if you own two business, each business could be a campaign. `,
    title: 'What is a campaign?',
    tooltip: 'What is a campaign?',
    tooltipPlacement: 'right',
    footerButton: 'close',
  }

  const { fields } = useFieldArray({
    control,
    name: 'askUsers',
  })
  const watchFields = watch('askUsers')

  const onSubmit = async (data) => {
    getData({ formDetails: data })
    setIsDone(true)
    nextStep()
  }

  const AddressCheckBox = ({ field, index, register }) => {

    const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

    return (
      <Tooltip
        isOpen={isOpen}
        label={'This includes: Street, apt City, State, Zip and Phone'}
        hasArrow
        bg='teal.400'
        maxWidth={'200px'}
        placement='bottom'
      >
        <Checkbox
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          onClick={onToggle}
          key={field.id}
          type='checkbox'
          {...register(`askUsers.${index}.checked`)}
        >
          {field.label}
        </Checkbox>
      </Tooltip>
    )
  }

  return (
    <Stack width='full' spacing={4} my={[6, '8']}>
      <Stack width={['full', '90%', '80%']} mx='auto' my={6} justifyContent='center' spacing={4}>
        <Text fontSize='base' color='gray.400' textAlign='center'>
          Last Step
        </Text>
        <Heading
          color={'brand.1'}
          lineHeight={1.1}
          fontSize={{ base: 'lg', sm: 'xl', md: '3xl' }}
          textAlign='center'
        >
          After users record their video review, you can customize the information you would like
          them to provide.
        </Heading>
        <Text fontSize='base' color='gray.400' textAlign='center'>
          This information will be very useful in the future for your marketing.{' '}
        </Text>
      </Stack>

      <VStack spacing={6} alignItems='flex-start' as='form' onSubmit={handleSubmit(onSubmit)}>
        <Box ml={'12px !important'} pb='4' pr='2'>
          <HStack mb={4} alignItems={'center'}>
            <Text fontSize={['sm', 'md', 'xl']} color='black' fontWeight='bold'>
              Select the items to be filled in:
            </Text>
            <InformationModal
              icon={<BsPatchQuestionFill />}
              iconColor='teal.300'
              iconSize='1.5em'
              modalData={items1}
            />
          </HStack>

          <CheckboxGroup colorScheme='teal'>
            <HStack
              spacing={0}
              gap={[4]}
              sx={{
                '& label': {
                  minWidth: '90px',
                  gap: '5px',
                },
              }}
              flexWrap='wrap'
            >
              {fields.map((field, index) => (
                field?.name === 'address' ?
                  <AddressCheckBox
                    key={field.id}
                    field={field}
                    index={index}
                    register={register}
                  /> :
                  <Checkbox
                    key={field.id}
                    type='checkbox'
                    disabled={field.name === 'name' || field.name === 'email'}
                    {...register(`askUsers.${index}.checked`)}
                  >
                    {field.label}
                    {/* {(field.name).split("_").join(" ")} */}
                  </Checkbox>
              ))}
            </HStack>
          </CheckboxGroup>
          {errors?.askUsers && (
            <Text color={'red.400'} mt={4} textAlign='left'>
              {errors?.askUsers.message}
            </Text>
          )}
        </Box>
        <Box ml={'12px !important'} pb='4' pr='2'>
          <HStack mb={4} alignItems={'center'}>
            {' '}
            <Text fontSize={['sm', 'md', 'xl']} color='black' fontWeight='bold'>
              Select to make required:
            </Text>
            <InformationModal
              icon={<BsPatchQuestionFill />}
              iconColor='teal.300'
              iconSize='1.5em'
              modalData={items2}
            />
          </HStack>
          <CheckboxGroup colorScheme='teal'>
            <HStack
              spacing={0}
              gap={[4]}
              sx={{
                '& label': {
                  minWidth: '90px',
                  gap: '5px',
                },
              }}
              flexWrap='wrap'
            >
              {fields.map((field, index) => {
                let findField = watchFields.find((a) => a.name === field.name)
                if (!findField.checked) return false
                else
                  return (
                    <Checkbox
                      key={field.id}
                      type='checkbox'
                      disabled={field.name === 'name' || field.name === 'email'}
                      {...register(`askUsers.${index}.required`)}
                    >
                      {field.label}
                    </Checkbox>
                  )
              })}
            </HStack>
          </CheckboxGroup>
        </Box>

        <HStack width='100%' justify='flex-end'>
          <Button
            isDisabled={activeStep === 0}
            mr={0}
            onClick={prevStep}
            size='sm'
            variant='outline'
            minWidth='70px'
          >
            Prev
          </Button>
          <Button
            size='sm'
            type='submit'
            variant='solid'
            isLoading={isSubmitting}
            onClick={() => {
              let count = watchFields.reduce((val, itm) => (itm.checked ? val + 1 : val + 0), 0)
              if (count <= 1)
                return setError(
                  `askUsers`,
                  { type: 'minLength', message: 'Select at least 2 fields' },
                  { shouldFocus: true }
                )
              else return clearErrors('askUsers')
            }}
          >
            {state.id ? 'Update' : 'Generate'} Campaign
          </Button>
        </HStack>
      </VStack>
    </Stack>
  )
}

export default FormDetails
