import React from "react"
import {
    Box,
    Text,
    IconButton,
    Button,
    useDisclosure,
    ModalOverlay,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
    Tooltip,
    Image,
    AspectRatio
} from '@chakra-ui/react'



export const InformationModal = ({ icon, iconColor, iconSize, modalData }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Box>
                <Tooltip
                    color='white'
                    bg='teal.400'
                    px={2}
                    py={1}
                    fontSize="1rem"
                    fontWeight="medium"
                    rounded='md'
                    hasArrow
                    closeOnPointerDown
                    placement={modalData.tooltipPlacement}
                    label={modalData.tooltip}
                >
                    <IconButton
                        onClick={() => {
                            onOpen()
                        }}
                        icon={icon}
                        color={iconColor}
                        aria-label='information'
                        size='md'
                        fontSize={iconSize}
                        border='none'
                        isRound={true}
                        _hover={{
                            transform: "scale(1.2)",
                            bg: "whiteAlpha.300",
                            color: 'brand.2',
                            type: 'ghost'
                        }}
                    />
                </Tooltip>
            </Box>

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(5deg)'
                />
                <ModalContent>
                    <ModalHeader color='brand.1'>{modalData.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{modalData.body}</Text>
                        {modalData.image ?
                            <Box mt='2'><Image src={modalData.image} /></Box> : null}
                        {modalData.video ?
                            <AspectRatio mt='4' ratio={5 / 3} >
                                <iframe
                                    title='naruto'
                                    src={modalData.video}
                                    allowFullScreen
                                />
                            </AspectRatio>
                            : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>{modalData.footerButton}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}