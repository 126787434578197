import React from "react"
import {
    Text,
    Button,
    ModalOverlay,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
} from '@chakra-ui/react'

const ProfessionalVideoModal = ({ isOpen, onClose }) => {

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay
                bg='blackAlpha.600'
                backdropFilter='blur(5px) hue-rotate(5deg)'
            />
            <ModalContent mx={["2", 0]}>
                <ModalHeader color='brand.1'>Professional Video</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Coming Soon</Text>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ProfessionalVideoModal