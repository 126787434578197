import { Alert, AlertDescription, AlertIcon, AlertTitle, IconButton, Tooltip, chakra } from "@chakra-ui/react";
import { isMobile, browserName } from 'react-device-detect';
import { standardBrowserList } from "../../../utils";
import { AnimatePresence, motion } from "framer-motion";
import { BsPatchQuestionFill } from "react-icons/bs";

export function RecorderAlertBox({ onOpen }) {
    return (<Alert status='error' mb={'3'}>
        <AlertIcon />
        <chakra.div flexDirection={'column'}>
            <AlertTitle textTransform='capitalize'>{"Give permissions"}!</AlertTitle>
            <AlertDescription>
                {' '}
                {isMobile && !standardBrowserList?.includes(browserName)
                    ? 'Looks like your using built in browser try clicking the ... icon in top right corner > open system browser or open with etc '
                    : 'Give permission to record video and voice and refresh the page to see the changes!'}
            </AlertDescription>
        </chakra.div>
        <Tooltip
            label='Need Help ?'
            placement='bottom'
            color='white'
            bg='teal.400'
            px={2}
            rounded='md'
            hasArrow
        >
            <AnimatePresence>
                <motion.div
                    animate={{
                        scale: [1, 1.3, 1],
                    }}
                    transition={{
                        duration: 0.75,
                        delay: 2,
                        repeat: 5,
                        repeatType: 'reverse',
                    }}
                    style={{
                        alignSelf: 'flex-start',
                        marginRight: '-8px',
                        marginTop: '-6px',
                    }}
                >
                    <IconButton
                        color={'gray.600'}
                        variant='ghost'
                        size='md'
                        rounded={'full'}
                        onClick={onOpen}
                    >
                        <BsPatchQuestionFill fontSize={'26px'} />
                    </IconButton>
                </motion.div>
            </AnimatePresence>
        </Tooltip>
    </Alert>)
}