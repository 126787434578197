import React from 'react'

const TickTock = ({ fill, stroke, width, height }) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width || "18.000000pt"} height={height || "18.000000pt"} viewBox="0 0 100.000000 100.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
                Created by potrace 1.16, written by Peter Selinger 2001-2019
            </metadata>
            <g transform="translate(0.000000,100.000000) scale(0.019531,-0.019531)"
                fill={fill || "current"} stroke={stroke || "current"}>
                <path d="M2235 5105 c-462 -60 -885 -237 -1255 -523 -117 -91 -316 -287 -413
                        -407 -276 -342 -459 -746 -537 -1186 -28 -156 -39 -531 -21 -702 43 -399 182
                        -790 398 -1122 98 -150 184 -257 318 -394 419 -430 948 -690 1550 -760 134
                        -16 460 -13 605 4 748 93 1416 512 1833 1150 210 321 344 690 392 1075 20 162
                        20 477 0 640 -92 742 -510 1412 -1140 1826 -329 216 -695 350 -1085 399 -152
                        18 -501 18 -645 0z m1043 -822 c39 -309 214 -556 486 -688 103 -50 234 -85
                        319 -85 l57 0 0 -312 0 -311 -94 6 c-243 16 -516 108 -708 238 -31 22 -59 39
                        -62 39 -4 0 -6 -310 -6 -689 0 -756 -1 -778 -61 -955 -143 -428 -544 -740
                        -992 -774 -342 -26 -660 92 -903 335 -230 230 -346 531 -331 862 21 485 358
                        908 831 1046 109 31 296 51 389 41 l67 -8 0 -313 0 -313 -37 9 c-21 5 -72 9
                        -113 9 -140 0 -254 -46 -360 -146 -112 -106 -164 -230 -163 -389 2 -390 406
                        -639 762 -468 136 64 249 206 279 348 12 55 22 997 22 2008 l0 607 303 0 303
                        0 12 -97z"
                />
            </g>
        </svg>

    )
}

export default TickTock