import { Box, Button, Container, Stack } from '@chakra-ui/react'
import * as React from 'react'
import { Link } from 'react-router-dom'

export const LearnMore = () => (
  <Box rounded='xl' as='section' bg={'white'}>
    <Container
      pb={{
        base: '4',
        md: '6',
      }}
      pt={{
        base: '1',
        md: '2',
      }}
      w='40%'
    >
      <Stack
        spacing={{
          base: '8',
          md: '10',
        }}
      >
        <Button as={Link} to={'/register'} px={'4'} size='lg' fontSize='md'>
          Learn more
        </Button>
      </Stack>
    </Container>
  </Box>
)
