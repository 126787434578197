import { Input, Textarea } from '@chakra-ui/react'
import React from 'react'

function InputField({ register, name, required, label }) {
    const inputProps = {
        placeholder: label,
        size: ["sm", "md", "lg"],
        bg: 'gray.100',
        border: 0,
        color: 'gray.500',
        _placeholder: {
            textTransform: 'capitalize',
            color: 'gray.500',
        },
        noOfLines: name !== "reviewMessage" ? 1 : 4,
        resize: "none",
    }

    if (name !== "reviewMessage") return (
        <Input
            {...inputProps}
            {...register(name, {
                required: required && 'This is required',
                pattern:
                    name === 'email' ? {
                        // eslint-disable-next-line no-useless-escape
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address"
                    }
                        :
                        null
            })}
        />
    )
    else return (
        <Textarea
            {...inputProps}
            py={2}
            {...register(name, {
                required: required && 'This is required',
                maxLength: {
                    value: 170,
                    message: "should be less than 170 characters"
                },
                minLength: {
                    value: 3,
                    message: "should be greater than 3 characters"
                }
            })}
        />
    )
}

export default InputField