export const cookieData = `
Effective: December 1, 2022

At Sendmea, we value your privacy and are committed to protecting your personal data. We use cookies on our website to enhance your user experience and to collect data that helps us improve our website and services.

What are cookies?
Cookies are small data files that are placed on your device when you visit a website. Cookies help websites recognize your device and remember information about your preferences, settings, and browsing habits.

What cookies do we use?
We use both session and persistent cookies on our website. Session cookies are temporary and expire when you close your browser. Persistent cookies remain on your device until they expire or are deleted. We use cookies for the following purposes:

Essential cookies: These cookies are necessary for the website to function properly and cannot be disabled. They include cookies that enable you to log in to secure areas of the website, use a shopping cart, or make use of e-billing services.

Performance cookies: These cookies collect information about how visitors use our website, such as which pages they visit most often and whether they receive error messages from web pages. We use this information to improve the performance and functionality of our website.

Functionality cookies: These cookies allow our website to remember the choices you make (such as your username, language, or region) and to provide enhanced, personalized features.

Analytics cookies: We use Google Analytics to collect anonymous information about how visitors use our website. This information is used to create reports and to help us improve the website. Google Analytics cookies remain on your device for up to two years.

Managing cookies:
You can control cookies through your browser settings. Most browsers allow you to refuse cookies or to delete cookies that have already been placed on your device. However, please note that disabling cookies may affect your user experience and may prevent you from using certain features of our website.

Data sharing:
We do not sell or share your personal data with third parties. However, we may share anonymous data collected through cookies with our service providers to help us improve our website and services.

Policy updates:
We may update this cookie policy from time to time. We encourage you to review this policy regularly for any changes.

If you have any questions or concerns about our use of cookies, please contact us at staff@sendmea.io.`
