import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Input,
  Text,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useCustomToast } from "../../hooks/customToast";
import { errorToast } from "../../utils";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/atoms/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/config";

const Webhook = ({ selectedCampaign = null }) => {
  const { state } = useLocation();
  const { id: campaignId } = useParams();
  const id = selectedCampaign?.id || campaignId;
  const [isEditing, setIsEditing] = useState(false);

  const [webhook, setWebhook] = useState(null);
  const [addedBy, setAddedBy] = useState(null);
  const [isTesting, setIsTesting] = useState(false);
  const { addToast } = useCustomToast();
  const user = useRecoilValue(userState);

  const campaignDetails = state || selectedCampaign;

  // React Hook Form setup
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  // Handle form submission
  const onSubmit = async (data) => {
    const newWebhook = data.webhookUrl;
    const newMetadata = {
      addedBy: user.uid || "",
      ...(!webhook?.webhookUrl && { addedAt: new Date().toLocaleString() }),
      updatedAt: new Date().toLocaleString(),
      webhookUrl: newWebhook,
    };
    setWebhook(newMetadata);

    await updateDoc(doc(db, "campaigns", id), {
      webhook: newMetadata,
    });
    addToast({
      title: "Webhook saved.",
    });
    setIsEditing(false);
    // reset();
  };

  // Handle test button click
  const handleTest = async () => {
    if (!webhook) {
      errorToast(
        {
          message: "No webhook URL set.",
        },
        addToast
      );
      return;
    }

    setIsTesting(true);
    try {
      const response = await fetch(webhook, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ test: "This is a test webhook payload." }),
      });

      if (response.ok) {
        addToast({
          title: "Test webhook sent successfully.",
          duration: 3000,
          isClosable: true,
        });
      } else {
        errorToast(
          {
            message: "Failed to send test webhook.",
          },
          addToast
        );
      }
    } catch (error) {
      errorToast(error, addToast);
    } finally {
      setIsTesting(false);
    }
  };

  useEffect(() => {
    !!webhook?.addedBy &&
      getDoc(doc(db, "users", webhook.addedBy)).then((docSnap) => {
        if (docSnap.exists()) {
          setAddedBy(docSnap.data());
        }
      });
  }, [webhook]);

  useEffect(() => {
    if (!!state?.webhook || !!selectedCampaign?.webhook) {
      setWebhook(state?.webhook || selectedCampaign.webhook);
      reset({
        webhookUrl:
          state?.webhook?.webhookUrl || selectedCampaign?.webhook?.webhookUrl,
      });
    } else {
      getDoc(doc(db, "campaigns", id)).then((docSnap) => {
        if (docSnap.exists()) {
          setWebhook(docSnap.data()?.webhook);
          reset({ webhookUrl: docSnap.data()?.webhook?.webhookUrl });
        }
      });
    }
  }, []);
  console.log({ campaignDetails });

  return (
    <Box
      minH='80vh'
      bgGradient='linear(to-r, teal.500, green.500)'
      display='flex'
      alignItems='center'
      justifyContent='center'
      padding={4}>
      <Box
        bg='white'
        p={8}
        borderRadius='md'
        boxShadow='lg'
        width={{ base: "100%", sm: "500px" }}>
        <VStack spacing={6} align='stretch'>
          {/* Webhook Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.webhookUrl}>
              <FormLabel>
                Enter your Webhook URL for{" "}
                {campaignDetails?.name ||
                  campaignDetails?.campaignDetails?.publicCampaignName ||
                  id}
              </FormLabel>
              <Input
                type='url'
                disabled={!isEditing}
                placeholder='https://example.com'
                {...register("webhookUrl", {
                  // required: "Webhook URL is required",
                  // pattern: {
                  //   value:
                  //     /^(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w\-.]*)*\/?$/,
                  //   message: "Enter a valid URL",
                  // },
                })}
              />
              <FormErrorMessage>
                {errors.webhookUrl && errors.webhookUrl.message}
              </FormErrorMessage>
            </FormControl>

            <Button
              mt={4}
              disabled={!isEditing}
              colorScheme='teal'
              width='full'
              type='submit'
              isLoading={isSubmitting}>
              {webhook ? "Update" : "Save"}
            </Button>
          </form>
          {!isEditing && (
            <Button
              width='full'
              mt={4}
              type='button'
              onClick={() => setIsEditing(true)}>
              Edit
            </Button>
          )}
          {/* Render Webhook and Test Button */}
          {webhook && (
            <Box>
              <HStack justifyContent='space-between' mb={2}>
                <Text fontWeight='medium'>Webhook URL:</Text>
                {/* <Button size='sm' onClick={handleTest} isLoading={isTesting}>
                  Test
                </Button> */}
              </HStack>
              <Text bg='gray.100' p={2} borderRadius='md' wordBreak='break-all'>
                {webhook?.webhookUrl}
              </Text>
              <Box mt={4}>
                <Text fontSize='sm' color='gray.600'>
                  Added by:{" "}
                  {addedBy?.firstName
                    ? `${addedBy?.firstName} ${addedBy?.lastName}`
                    : addedBy?.email}
                </Text>
                <Text fontSize='sm' color='gray.600'>
                  Date: {webhook?.updatedAt}
                  {/* {webhook?.updatedAt?.toDate
                    ? format(
                        new Date(
                          webhook.updatedAt?.toDate()?.toDateString() ||
                            "01-01-2023"
                        ),
                        "MMM dd yyyy"
                      )
                    : format(
                        new Date(
                          webhook.updatedAt?.seconds * 1000 +
                            webhook.updatedAt?.nanoseconds / 1000000
                        ),
                        "MMM dd yyyy"
                      )} */}
                </Text>
              </Box>
            </Box>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default Webhook;
