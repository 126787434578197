import {
  Box,
  Heading,
  Stack,
  chakra,
  Flex,
  Text,
  theme,
  VStack,
  FormControl,
  Button,
  FormErrorMessage,
  HStack,
  Checkbox,
  Input,
} from '@chakra-ui/react'
import React from 'react'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'

const Circle = ({ no }) => (
  <chakra.div
    minWidth={'26px'}
    minHeight={'25px'}
    bg='brand.1'
    borderRadius='full'
    color='white'
    textAlign='center'
    display={'flex'}
    justifyContent='center'
    alignItems={'center'}
    mr='4px'
    fontSize={['sm', 'md']}
  >
    {no}
  </chakra.div>
)

const VideoDetails = ({ state, stepMethods, business, getData, submitStepRef1 }) => {
  let { nextStep, prevStep, activeStep } = stepMethods

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    // mode: "onBlur",
    defaultValues: {
      timerChecked: true,
      countdown: 3,
      reviewPageName: `'Record a video review for ${business?.businessName || '<Your business name>'
        }'.`,
      minRecordLength: '',
      maxRecordLength: '',
      ...(state?.videoDetails || {}),
    },
  })

  const onSubmit = async (data) => {
    const { timerChecked } = data
    getData({ videoDetails: { ...data, countdown: timerChecked ? 3 : 0 } })
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    nextStep()
  }

  const inputsDivRef = useRef(null)

  return (
    <Stack width='full' spacing={4} my={[8, '10']}>
      <Heading
        color={'brand.1'}
        lineHeight={1.1}
        fontSize={{ base: 'xl', sm: '3xl', md: '4xl' }}
        textAlign='center'
        width={['full', '90%', '80%']}
        mx='auto'
        mb={6}
      >
        Set Video Details For Users
      </Heading>
      <Stack
        spacing={10}
        as='form'
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          '& .chakra-text': {
            textAlign: 'left',
          },
        }}
        ref={inputsDivRef}
      >
        <VStack spacing={2} alignItems='stretch' maxWidth={'700px'}>
          <Flex direction={'row'} alignItems={['center']}>
            <Circle no={1} />
            <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
              Pre recorded countdown timer
            </Text>
          </Flex>
          <Box
            ml={'12px !important'}
            pl='24px'
            borderLeft={`2px solid ${theme.colors.gray['200']}`}
            pb='4'
          >
            <Text pb='16px' textAlign='left'>
              Checking it will set the countdown timer for 3 seconds for users before they record
              review
            </Text>
            <FormControl isInvalid={!!errors?.timerChecked}>
              <Checkbox type='checkbox' colorScheme='teal' size='md' {...register(`timerChecked`)}>
                Set Pre Record Timer
              </Checkbox>
              {errors.timerChecked && (
                <FormErrorMessage>{errors.timerChecked.message}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
        </VStack>
        <VStack spacing={2} alignItems='stretch' maxWidth={'700px'}>
          <Flex direction={'row'} alignItems={['center']}>
            <Circle no={2} />
            <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
              Provide the name you want to show on your record a review page?
            </Text>
          </Flex>
          <Box
            ml={'12px !important'}
            pl='24px'
            borderLeft={`2px solid ${theme.colors.gray['200']}`}
            pb='4'
          >
            {/* <Text pb="16px" textAlign="left">
                            The name will be used as a title for customers so they will know 
                        </Text> */}
            <FormControl isInvalid={!!errors?.reviewPageName}>
              <Input
                size={['sm', 'md', 'lg']}
                // placeholder=
                bg={'gray.100'}
                border={0}
                _placeholder={{
                  color: 'gray.500',
                }}
                _focus={{
                  color: 'gray.800',
                }}
                {...register('reviewPageName', {
                  required: 'This is required',
                })}
              // onClick={() => setThanksTestimonialColor('black')}
              />
              {errors?.reviewPageName && (
                <FormErrorMessage>{errors?.reviewPageName.message}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
        </VStack>
        <VStack spacing={2} alignItems='stretch' maxWidth={'700px'}>
          <Flex direction={'row'} alignItems={['center']}>
            <Circle no={3} />
            <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
              Minimum record length for the video
            </Text>
          </Flex>
          <Box
            ml={'12px !important'}
            pl='24px'
            borderLeft={`2px solid ${theme.colors.gray['200']}`}
            pb='4'
          >
            <Text pb='16px' textAlign='left'>
              Coming soon
            </Text>
          </Box>
        </VStack>
        <VStack spacing={2} alignItems='stretch' maxWidth={'700px'}>
          <Flex direction={'row'} alignItems={['center']}>
            <Circle no={4} />
            <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
              Maximum record length for the video
            </Text>
          </Flex>
          <Box
            ml={'12px !important'}
            pl='24px'
            borderLeft={`2px solid ${theme.colors.gray['200']}`}
            pb='4'
          >
            <Text pb='16px' textAlign='left'>
              Coming soon
            </Text>
          </Box>
        </VStack>
        <VStack spacing={2} alignItems='stretch' maxWidth={'700px'}>
          <Flex direction={'row'} alignItems={['center']}>
            <Circle no={5} />
            <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
              Add logo overlay to download
            </Text>
          </Flex>
          <Box
            ml={'12px !important'}
            pl='24px'
            borderLeft={`2px solid ${theme.colors.gray['200']}`}
            pb='4'
          >
            <Text pb='16px' textAlign='left'>
              Coming soon
            </Text>
          </Box>
        </VStack>

        <HStack width='100%' justify='flex-end'>
          {!state.id && (
            <Button
              isDisabled={activeStep === 0}
              mr={0}
              onClick={prevStep}
              size='sm'
              variant='outline'
              minWidth='70px'
            >
              Prev
            </Button>
          )}
          <Button
            size='sm'
            type='submit'
            variant='solid'
            minWidth={state.id ? '80px' : '70px'}
            // disabled={!shouldRenders.motivatePeopleText}
            id='next-btn'
            ref={submitStepRef1}
          >
            Next
          </Button>
        </HStack>
      </Stack>
    </Stack>
  )
}

export default VideoDetails
