import {
  Text,
  Container,
  Flex,
  Box,
  useBreakpointValue,
  useDisclosure, Modal, ModalOverlay, Tooltip, ModalContent, ModalCloseButton, IconButton
} from '@chakra-ui/react'
import React from 'react'
// import Social from './SocialMedia'
import { ProfileBody } from './ProfileBody'
import { ContactModal } from '../ContactModal/ContactModal'
import { Sidebar } from './Sidebar'
import { getNameInitials } from '../../../../utils'
import { MobileBar } from '../ContactModal/MobileBar'


export const ProfileModal = ({ campaignData, disableIcon }) => {

  const { campaignDetails } = campaignData

  const { isOpen, onOpen, onClose } = useDisclosure()

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <div>
      <>
        <Tooltip
          label='Visit Profile'
          fontSize='md'
          placement='bottom'
          bg='teal.400'
          rounded='md'
          hasArrow
          closeOnPointerDown>
          {/* <Avatar
            // src='https://res.cloudinary.com/codeshock/image/upload/v1672785398/michael-dam-mEZ3PoFGs_k-unsplash_okdhw2.jpg'
            name={campaignDetails?.publicCampaignName || campaignDetails?.internalCampaignName || "-"}
            // *** fallback is profile icon unless you add name and that will default to initials ***
            bg={'#B4E0E2'}
            size={'md'}
            onClick={_ => { onOpen(); console.log({ campaignDetails }) }}
            cursor="pointer"
            color="white"
          /> */}
          <IconButton
            variant="solid"
            // icon={<MdOutlineEmail fontSize="2rem" />}
            aria-label="Open Menu"
            color={'gray.50'}
            bg={'#78bdc0'}
            borderRadius="full"
            fontSize="1.4rem"
            onClick={() => !disableIcon && onOpen()}
            sx={{ width: '55px', height: '55px' }}
          >
            <Text>
              {getNameInitials(campaignDetails?.publicCampaignName || campaignDetails?.internalCampaignName || "-")}
            </Text>
          </IconButton>
        </Tooltip>
        <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} >
          <ModalOverlay />
          <ModalContent maxW="45rem" >
            <Flex
              as="section"
              direction={{
                base: 'column',
                lg: 'row',
              }}
              minHeight="60vh"
              bg="bg-canvas"
              overflowY="auto"
            >

              {isDesktop ? <Sidebar /> : null}

              <Container py="2" flex="1">
                <ProfileBody campaignData={campaignData} />

              </Container>
              {isDesktop ? <> <Box pb={4} mr={4} alignSelf={'end'}>
                <Flex align={'center'} justify='space-between' >
                  {/* <Text fontWeight={'bold'} pr='4' color='#03989E'>Contact</Text> */}

                  <ContactModal campaignData={campaignData}/>

                </Flex>

              </Box></> : <Box pb={4} mr={6} >
                <Flex align={'center'} justify='space-between' >
                  {/* <Text fontWeight={'bold'} pr='4' color='#03989E'>Contact</Text> */}
                  <MobileBar />
                  <ContactModal />

                </Flex>

              </Box>}


            </Flex>
            <ModalCloseButton />
          </ModalContent>
        </Modal>
      </>

    </div>
  )
}
