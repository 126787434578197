
import React from 'react'

const ExclamationIcon = ({ ...others }) => {
    return (
        <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" {...others}><g id="Layer_2" data-name="Layer 2"><g id="exclamation"><path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm35.83 360.17a35.83 35.83 0 0 1 -35.83 35.83 35.83 35.83 0 0 1 -35.83-35.83 35.83 35.83 0 0 1 35.83-35.83 35.83 35.83 0 0 1 35.83 35.83zm4.76-206.87-4.73 119.44a35.89 35.89 0 0 1 -35.86 34.46 35.89 35.89 0 0 1 -35.86-34.46l-4.73-119.44a35.89 35.89 0 0 1 35.86-37.3h9.46a35.89 35.89 0 0 1 35.86 37.3z" /></g></g></svg>

    )
}

export default ExclamationIcon